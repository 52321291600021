<template>
    <TheContentHeader titlePage="Lista de Usuarios" />
    <the-content-body>
        <widget-loading v-if="isLoadingUser" />
        <template v-else>
            <div class="row">
                <!-- Foto de Perfil -->
                <div class="col-lg-4">
                    <!-- Perfil -->
                    <div class="card">
                        <div class="card-body text-center">
                            <the-user-avatar :name="userProfile.name" avatarSize="xl"></the-user-avatar>
                            <!-- <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                                alt="avatar" class="rounded-circle img-fluid" style="width: 150px;"> -->
                            <h5 class="my-2">{{ userProfile.alias }}</h5>
                            <p class="text-muted mb-4">{{ userProfile.perfilDescripcion }}</p>
                            <!-- <p class="text-muted mb-4">Bay Area, San Francisco, CA</p> -->
                            <div class="d-flex justify-content-center mb-2">
                                <button type="button" data-mdb-button-init data-mdb-ripple-init
                                    class="btn btn-primary">Follow</button>
                                <button type="button" data-mdb-button-init data-mdb-ripple-init
                                    class="btn btn-outline-primary ms-1">Message</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Datos del usuario -->
                <div class=col-lg-8>
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-3">
                                    <p class="mb-0">Nombre y Apellido</p>
                                </div>
                                <div class="col-sm-9">
                                    <p class="text-muted mb-0">{{ userProfile.name }}</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-3">
                                    <p class="mb-0">Usuario</p>
                                </div>
                                <div class="col-sm-9">
                                    <p class="text-muted mb-0">{{ userProfile.username }}</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-3">
                                    <p class="mb-0">Email</p>
                                </div>
                                <div class="col-sm-9">
                                    <p class="text-muted mb-0">{{ userProfile.email }}</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-3">
                                    <p class="mb-0">Celular</p>
                                </div>
                                <div class="col-sm-9">
                                    <p class="text-muted mb-0">{{ userProfile.celular }}</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-3">
                                    <p class="mb-0">Tipo Documento</p>
                                </div>
                                <div class="col-sm-9">
                                    <p class="text-muted mb-0">{{ userProfile.nombreTipoDoc }}</p>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-sm-3">
                                    <p class="mb-0">No. Documento</p>
                                </div>
                                <div class="col-sm-9">
                                    <p class="text-muted mb-0">{{ userProfile.numeroDoc }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Botonoes de navegación -->
            <div class="d-flex justify-content-evenly mt-2  mb-2 bg-light rounded">
                <base-button-forms :classButton="tap === 1 ? 'btn-primary m-2' : 'btn-outline-primary m-2'" label="Información"
                    type="button" icon="fa-solid fa-circle-info" :onClick="() => tap = 1"
                    :permissionId="0"></base-button-forms>
                <base-button-forms :classButton="tap === 2 ? 'btn-primary m-2' : 'btn-outline-primary m-2'" label="Configuración"
                    type="button" icon="fa-solid fa-users" :onClick="() => tap = 2"
                    :permissionId="0"></base-button-forms>
                <base-button-forms :classButton="tap === 3 ? 'btn-primary m-2' : 'btn-outline-primary m-2'" label="Seguridad"
                    type="button" icon="fa-solid fa-file" :onClick="() => tap = 3"
                    :permissionId="0"></base-button-forms>
            </div>
            <transition name="fade">
                <!-- Datos usuario almacen -->
                <div v-if="tap == 1">
                    <div class="row">
                        <!-- Datos Aliado -->
                        <div class="col-lg-4">
                            <div class="card mb-4 mb-md-0">
                                <div class="card-body">
                                    <p class="mb-4 fw-bold">Información Aliado</p>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <p class="mb-0">Nombre</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p class="text-muted mb-0">{{ userProfile.nombreAliado }}</p>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <p class="mb-0">Tipo Doc</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p class="text-muted mb-0">{{ userProfile.NombreTipoIdAliado }}</p>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <p class="mb-0">No. Doc</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p class="text-muted mb-0">{{ userProfile.NumIdentificacionAliado }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Datos Almacén Activo -->
                        <div class="col-lg-8 mb-4">
                            <div class="card mb-4 mb-md-0">
                                <div class="card-body">
                                    <p class="mb-4 fw-bold">Información Almacén activo</p>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <p class="mb-0">Nombre</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p class="text-muted mb-0">{{ userProfile.nombreAlmacen }}</p>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <p class="mb-0">Dirección</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p class="text-muted mb-0">{{ userProfile.direccionAlmacen }}</p>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <p class="mb-0">Rol</p>
                                        </div>
                                        <div class="col-sm-9">
                                            <p class="text-muted mb-0">{{ userProfile.perfilDescripcion }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!-- Almacenes Asociados -->
                        <div class="col-lg-12 ">
                            <div class="card mb-4 mb-lg-0">
                                <div class="card-body p-0 m-3">
                                    <p class="mb-4 text-center fw-bold">Almacenes Asociados ( {{ almacenes.length }})
                                    </p>
                                    <base-table-forms :data="almacenes" :headers="headers" :whitPagination="false"
                                        :orderColumn="column" :order="order" :whitOrder="true"
                                        :dataFunction="orderList">
                                        <tr v-for="almacen in almacenes" :key="almacen.Ca110CodAlmacen">
                                            <td class="text-center">{{ almacen.Ca110CodAlmacen }}</td>
                                            <td class="text-center">{{ almacen.Ca110NombreComercial }}</td>
                                            <td class="text-center">{{ almacen.Ca110Direccion }}</td>
                                            <td class="text-center">{{ almacen.rolUsuario.description }}</td>
                                        </tr>
                                    </base-table-forms>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </template>
    </the-content-body>
</template>

<script>
import useAuth from '@/modules/auth/composables/useAuth'
import useUsers from '@/modules/users/composables/useUsers'
import { orderArray } from '@/helpers/utilities'

import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import TheUserAvatar from '@/modules/dashboard/components/TheUserAvatar.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';

import { ref } from 'vue'

export default {
    props: {
        userId: {
            type: String,
        }

    },
    components: {
        BaseButtonForms,
        BaseTableForms,
        TheContentBody,
        TheContentHeader,
        TheUserAvatar,
        WidgetLoading,
    },
    setup(props) {
        const userProfile = ref({})
        const almacenes = ref([])
        const column = ref()
        const order = ref()
        const tap = ref(1)
        const headers = ref(
            [
                { encabezado: 'Código', columna: 'Ca110CodAlmacen' },
                { encabezado: 'Nombre', columna: 'Ca110NombreComercial' },
                { encabezado: 'Dirección', columna: 'Ca110Direccion' },
                { encabezado: 'Rol', columna: null },
            ]
        )
        const { getUserProfile, isLoadingUser } = useUsers()
        const { userDataLoggedIn } = useAuth()
        const callGetUserProfile = async (userId) => {
            const { response, data } = await getUserProfile(userId);
            if (response) {
                userProfile.value = data.user
                almacenes.value = data.almacenes
            }
        }

        callGetUserProfile(props.userId ? props.userId : userDataLoggedIn.value.id)


        const orderList = (page, col) => {
            if (col) {
                column.value = col
                order.value = order.value == 'asc' ? 'desc' : 'asc'
            }
            almacenes.value = orderArray(almacenes.value, column.value, order.value)
        }





        return {
            userProfile,
            almacenes,
            headers,
            isLoadingUser,
            column,
            order,
            orderList,
            tap
        }

    }

}
</script>

<style scoped>
.scroll {
    overflow-y: scroll;
    max-height: 200px;
}
</style>