<template>
    <div class="card h-100">
        <div class="card-header">
            <i class="fas fa-credit-card"></i> Solicitudes de crédito
        </div>
        <div class="card-body">
            <form>
                <div class="form-group row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-forms v-model="formData.codAlmacen" title="Almacenes" idSelect="codAlmacen"
                            :options="shopAuthorizedLists" optionName="nombreAlmacen" optionId="codAlmacen"
                            :errors="alert.errorsFields" :onChange="loadGraphics"></base-select-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-forms v-model="formData.tipoRangoFecha" title="Fecha"
                            idSelect="tipoRangoFechaSolicitud" :options="reportsParameters.rangoFechaFiltro"
                            optionName="1" optionId="0" :errors="alert.errorsFields"
                            :onChange="loadGraphics"></base-select-forms>
                    </div>
                </div>
            </form>
            <widget-loading v-if="isLoading.requestGraphics" />
            <div class="d-flex justify-content-center">
                <div style="width: 80%;height: 80%;">
                    <canvas v-show="!isLoading.requestGraphics" id="graphicsRequest"></canvas>
                </div>
            </div>

        </div>
        <div class="card-footer">
            <div class="row" v-show="!isLoading.requestGraphics">
                <div class="col-lg-6 col-sm-12" style="overflow: auto;">
                    <base-table-forms :headers="['Usuario', '# Solicitudes']" :whitPagination="false">
                        <tr v-for="usuario in requestGraphics.usuariosAno1" :key="usuario">
                            <td class="text-center">{{ usuario[0].nombreUsuarioAlmacen }}</td>
                            <td class="text-center">{{ usuario.length }}</td>
                        </tr>
                    </base-table-forms>
                </div>
                <div class="col-lg-6 col-sm-12" style="overflow: auto;">
                    <base-table-forms :headers="['Usuario', '# Solicitudes']" :whitPagination="false">
                        <tr v-for="usuario in requestGraphics.usuariosAno2" :key="usuario">
                            <td class="text-center">{{ usuario[0].nombreUsuarioAlmacen }}</td>
                            <td class="text-center">{{ usuario.length }}</td>
                        </tr>
                    </base-table-forms>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <base-button-forms classButton="btn-primary" icon="fa-solid fa-file-export" :permissionId="68"
                    :onClick="onExportToExcel" :isLoading="isLoading['requestGraphicsExport']"
                    :disabled="isLoading['requestGraphicsExport']"></base-button-forms>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, computed, onMounted } from 'vue'
import Chart from 'chart.js/auto';


import useReports from '@/modules/reports/composables/useReports'
import graphics from '@/helpers/graphics'
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import useShop from '@/modules/shop/composables/useShop'
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';






export default {
    components: {
        WidgetLoading,
        BaseSelectForms,
        BaseTableForms,
        BaseButtonForms
    },

    setup() {
        const {
            getDataReports,
            requestGraphics,
            isLoading,
            alert,
            reportsParameters,
            exportToExcel
        } = useReports()

        const { barGraphicsOptions } = graphics()

        const { getShopAuthorizedLists, shopAuthorizedLists } = useShop()

        const formData = ref({
            codAlmacen: '',
            tipoRangoFecha: '0',
            tipoReporte: 'SO'

        })

        let chartRequest
        const loadGraphics = async () => {
            try {
                if (chartRequest) {
                    chartRequest.clear();
                    chartRequest.destroy();
                }
                const { response } = await getDataReports(formData.value, 'requestGraphics')
                if (response) {
                    const ctx = document.getElementById('graphicsRequest')
                    const opcionesGrafico = barGraphicsOptions({
                        tipoInforme: 'solicitud',
                        labels: requestGraphics.value.labels,
                        datasets: requestGraphics.value.datasets,
                        titulo: requestGraphics.value.title,
                        subtitulo: requestGraphics.value.subtitle,
                        textoEjeX: 'Año',
                        textoEjeY: 'Cantidad Solicitudes'
                    })
                    chartRequest = new Chart(ctx, opcionesGrafico, 'cantidad');
                    chartRequest
                }
            } catch (error) {
                console.log(error);

            }
        }

        getShopAuthorizedLists()


        onMounted(async () => {
            loadGraphics()
        })
        return {
            requestGraphics,
            isLoading,
            alert,
            formData,
            reportsParameters,
            shopAuthorizedLists,

            loadGraphics,
            onExportToExcel: async () => {
                await exportToExcel(formData.value, 'requestGraphics')
            }

        }
    },


}
</script>