import {
    isAuthenticatedGuard,
    isAuthorized,
} from '@/guard/auth-guard'
import UserProfile from '../../profile/views/UserProfile.vue'
export default {
    name: 'profiles',
    component: () =>
        import( /* webpackChunkName: "DashboardLayout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [
        {
            path: '',
            beforeEnter: [isAuthenticatedGuard,isAuthorized],
            name: 'my-profile',
            meta: {
                title: 'Perfil',
                icon: 'fas fa-users',
                showNav: false,
                permission: 8,
                // group: 'acount',
            },
            component: UserProfile
        },
        {
            path: '/profiles/:id',
            beforeEnter: [isAuthenticatedGuard,isAuthorized],
            name: 'profile',
            meta: {
                title: 'Perfil',
                icon: 'fas fa-users',
                showNav: false,
                permission: 8,
                // group: 'acount',
            },
            component: UserProfile,
            props: (router) => {
                return {
                    userId: router.params.id
                }
            },
        },
    ]



}