<template></template>
<script>
// Cerrar sesión por inactividad
import { onMounted, ref, watch } from 'vue';
import { useIdle } from "@vueuse/core";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useAuth from '@/modules/auth/composables/useAuth'

export default {
    setup() {
        const { logout, userParameters } = useAuth()
        const router = useRouter()
        const store = useStore()
        const tiempoSesion = (userParameters.value.tiempoSesion - 5) * 60 * 1000//tiempoSesion=minutos (1 minuto equivale a 60000 milisegundos, 1 segundo equivale a 1000 milisegundos)

        const { idle, lastActive } = useIdle(tiempoSesion)
        const onLogout = async () => {
            const { response } = await logout(true)
            if (response) {
                router.push({ name: 'login' })
            }
        }
        onMounted(() => {
            watch(idle, (idleValue) => {
                if (idle.value === true) {
                    onLogout()
                }
                console.log(idleValue);

            })
        })
        return {}
    },
}
</script>