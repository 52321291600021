<template>
    <div class="card h-100">
        <div class="card-header">
            <i class="fa-solid fa-cash-register"></i> Pagos
        </div>
        <div class="card-body">
            <form>
                <div class="form-group row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-forms v-model="formData.codAlmacen" title="Almacenes" idSelect="codAlmacen"
                            :options="shopAuthorizedLists" optionName="nombreAlmacen" optionId="codAlmacen"
                            :errors="alert.errorsFields" :onChange="loadGraphics"></base-select-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-forms v-model="formData.tipoRangoFecha" title="Fecha"
                            idSelect="tipoRangoFechaSolicitud" :options="reportsParameters.rangoFechaFiltro"
                            optionName="1" optionId="0" :errors="alert.errorsFields"
                            :onChange="loadGraphics"></base-select-forms>
                    </div>
                </div>
            </form>
            <widget-loading v-if="isLoading.paymentsGraphics" />
            <canvas v-show="!isLoading.paymentsGraphics" id="graphicsPayments"></canvas>
        </div>
        <div class="card-footer">
            <div class="d-flex justify-content-end">
                <base-button-forms classButton="btn-primary" icon="fa-solid fa-file-export" :permissionId="72"
                    :onClick="onExportToExcel" :isLoading="isLoading['paymentsGraphicsExport']"
                    :disabled="isLoading['paymentsGraphicsExport']"></base-button-forms>
            </div>
        </div>
    </div>
</template>

<script>

import { ref, computed, onMounted } from 'vue'
import Chart from 'chart.js/auto';


import useReports from '@/modules/reports/composables/useReports'
import graphics from '@/helpers/graphics'
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import useShop from '@/modules/shop/composables/useShop'
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';





export default {
    components: {
        WidgetLoading,
        BaseSelectForms,
        BaseButtonForms
    },

    setup() {
        const {
            getDataReports,
            paymentsGraphics,
            isLoading,
            alert,
            reportsParameters,
            exportToExcel
        } = useReports()

        const { barGraphicsOptions } = graphics()

        const { getShopAuthorizedLists, shopAuthorizedLists } = useShop()

        const formData = ref({
            codAlmacen: '',
            tipoRangoFecha: '0',
            tipoReporte:'RE'

        })

        let myChart
        const loadGraphics = async () => {
            try {
                if (myChart) {
                    myChart.clear();
                    myChart.destroy();
                }
                const { response } = await getDataReports(formData.value, 'paymentsGraphics')
                if (response) {
                    const ctx = document.getElementById('graphicsPayments')
                    const opcionesGrafico = barGraphicsOptions({
                        tipoInforme: 'Pagos',
                        labels: paymentsGraphics.value.labels,
                        datasets: paymentsGraphics.value.datasets,
                        titulo: paymentsGraphics.value.title,
                        subtitulo: paymentsGraphics.value.subtitle,
                        textoEjeX: 'Año',
                        textoEjeY: 'Monto Pagos',
                        tipoDato: 'monto'

                    })
                    myChart = new Chart(ctx, opcionesGrafico, 'monto');
                    myChart
                }
            } catch (error) {
                console.log(error)
            }
        }

        // getShopAuthorizedLists('caja')

        onMounted(async () => {
            loadGraphics()
        })
        return {
            paymentsGraphics,
            isLoading,
            alert,
            formData,
            reportsParameters,
            shopAuthorizedLists,

            loadGraphics,
            onExportToExcel: async () => {
                await exportToExcel(formData.value, 'paymentsGraphics')
            }


        }
    },


}
</script>