import { computed } from "vue";
import { useStore } from "vuex";

const useAllied = () => {
    const store = useStore()

    const loadAlliedList = async(formData) => {
        return await  store.dispatch('allied/loadAlliedList', { formData })
    }
    const createAllied = async(formData) => {
        return await  store.dispatch('allied/createAllied', { formData })
    }
    const getAllied = async(alliedId) => {
        return await  store.dispatch('allied/getAllied', { alliedId })
    }
    const updateAllied = async(alliedId,formData) => {
        return await  store.dispatch('allied/updateAllied', { alliedId,formData })
    }
    const alliedStudyDecision = async(alliedId,formData) => {
        return await  store.dispatch('allied/alliedStudyDecision', { alliedId,formData })
    }

    const getInfoAliado = async(tipoIdAliado, numIdAliado) => {
        return await  store.dispatch('allied/getInfoAliado', { tipoIdAliado, numIdAliado })
    }
    const getSimpleList = async() => {
        return await  store.dispatch('allied/getSimpleList')
    }


    

    return {
        loadAlliedList,
        createAllied,
        getAllied,
        updateAllied,
        getInfoAliado,
        alliedStudyDecision,
        getSimpleList,
        allied: computed(() => store.state['allied']['allied']),
        alliedList: computed(() => store.state['allied']['alliedList']),
        simpleListAllied: computed(() => store.state['allied']['simpleList']),
        alliedParameters: computed(() => store.state['allied']['alliedParameters']),
        errorsFields: computed(() => store.state['allied']['errorsFields']),
        isLoading: computed(() => store.state['allied']['isLoading']),
        isLoadingAllied: computed(() => store.state['allied']['isLoadingAllied']),
        isLoadingOperationAllied: computed(() => store.state['allied']['isLoadingOperationAllied']),
        alert: computed(() => store.state['allied']['alert']),
        


    }



}


export default useAllied