<template>
    <div class="typing-indicator" :class="className" id="typing">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="text">Escribiendo...</span>
    </div>
</template>

<script>


export default ({
    props:{
        className:{
            type:String,
            default:''
        }

    },
    setup() {

    },
})
</script>

<style scoped>
.typing-indicator {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #888;
    margin-left: 15px;
    /* Espaciado para simular que está alineado a la izquierda */
}

.typing-indicator .dot {
    height: 8px;
    width: 8px;
    margin: 0 2px;
    background-color: #3498db;
    /* Color del punto */
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.2s infinite ease-in-out;
}

.typing-indicator .dot:nth-child(1) {
    animation-delay: 0s;
}

.typing-indicator .dot:nth-child(2) {
    animation-delay: 0.2s;
}

.typing-indicator .dot:nth-child(3) {
    animation-delay: 0.4s;
}

.typing-indicator .text {
    margin-left: 5px;
}

@keyframes bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}
</style>