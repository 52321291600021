<template>
    <!-- Boton Flotante chat -->
    <transition name="fade">
        <button v-show="isChatCollapsed" class="floating-btn btn-primary" @click="toggleChat">
            <i class="fas fa-comments"></i>
        </button>
    </transition>
    <transition name="fade">
        <div v-show="!isChatCollapsed" class="card-deck fixed-chat">
            <div class="card card-info direct-chat" id="card-chat">
                <!-- {{ --Encabezado --}} -->
                <div class="card-header bg-primary">
                    <!-- Título -->
                    <h3 class="card-title" id="asuntoChat">Asunto: {{ currentChat.CA202ASUNTO }} </h3>
                    <!-- Botones de acción -->
                    <div class="card-tools">
                        <button type="button" class="btn btn-tool"
                            style="border-radius: 50%; background-color: transparent;"
                            @click="isChatCollapsed = !isChatCollapsed">
                            <i class="fas fa-minus"></i>
                        </button>

                        <button type="button" class="btn btn-tool" data-toggle="tooltip" title="Asuntos"
                            data-widget="chat-pane-toggle" style="border-radius: 50%; background-color: transparent;">
                            <i class="fas fa-comments"></i>
                        </button>
                        <!-- {{ --Icono Cerrar chat-- }} -->
                        <button v-if="currentChat.CA202CODCHAT && isOwnChat" type="button" class="btn btn-tool"
                            @click="onCloseChat(currentChat.CA202CODCHAT)"><i class="fas fa-times"></i>
                        </button>
                    </div>
                </div>
                <!-- Cuerpo -->
                <div class="card-body" style="height: 340px">
                    <!-- Lista de mensajes -->
                    <div class="direct-chat-messages" ref="containerMessageList" style="height: 100%"
                        wire:loading.remove wire:target='mensajes'>
                        <widget-loading v-if="isLoading" />
                        <!-- Message. Default to the left -->
                        <template v-else-if="chatMessage.length > 0">
                            <template v-for="(message, index) in chatMessage" :key="index">
                                <div v-if="message['envia'] != userDataLoggedIn.id" class="direct-chat-msg">
                                    <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-left">
                                            <!-- Puede ir el nombre del usuario -->
                                        </span>
                                        <span class="direct-chat-timestamp float-right">
                                            {{ getDiffForHumans(message['timEnvia']) }}
                                        </span>
                                    </div>
                                    <!-- /.direct-chat-infos -->
                                    <!-- Puede ir la imagen del usuario -->
                                    <!-- <img class="direct-chat-img" src="" alt="message user image"> -->
                                    <!-- /.direct-chat-img -->
                                    <div class="direct-chat-text text-break">
                                        {{ message['mensaje'] }}
                                    </div>
                                    <!-- /.direct-chat-text -->
                                </div>
                                <!-- /.direct-chat-msg -->
                                <!-- Message to the right -->
                                <div v-else class="direct-chat-msg right">
                                    <div class="direct-chat-infos clearfix">
                                        <span class="direct-chat-name float-right">
                                            <!-- Puede ir el nombre del usuario -->
                                        </span>
                                        <span class="direct-chat-timestamp float-left">
                                            {{ getDiffForHumans(message['timEnvia']) }}
                                        </span>
                                    </div>
                                    <!-- /.direct-chat-infos -->
                                    <!-- Puede ir la imagen del usuario -->
                                    <!-- <img class="direct-chat-img" src="" alt="message user image"> -->
                                    <!-- /.direct-chat-img -->
                                    <div v-if="message['envia'] === 1"
                                        class="direct-chat-text bg-danger text-light text-break">
                                        {{ message['mensaje'] }}
                                    </div>
                                    <div v-else class="direct-chat-text text-break bg-info">
                                        {{ message['mensaje'] }}
                                    </div>
                                    <!-- /.direct-chat-text -->
                                </div>
                            </template>
                        </template>
                        <!-- /.direct-chat-msg -->
                    </div>
                    <!-- Lista de asuntos  -->
                    <div class="direct-chat-contacts bg-info " style="height: 100%;">
                        <ul class="contacts-list">
                            <widget-loading v-if="isLoading" />
                            <template v-else>
                                <template v-for="chat in chatList" :key="chat.CA202CODCHAT">
                                    <li>
                                        <a style="cursor: pointer" data-widget="chat-pane-toggle"
                                            @click="getChatMessage(chat)">
                                            <!-- Imagen asunto -->
                                            <!-- <img class="contacts-list-img"
                                            src = "{{ App\Models\User::find($chat->CA202IDUSERORIGEN)->adminlte_image() }}"> -->
                                            <div class="contacts-list-info">
                                                <span class="contacts-list-name float-left" style="color: black">
                                                    {{ chat.CA202ASUNTO }}
                                                </span>
                                                <small class="contacts-list-date float-right" style="color: black">
                                                    {{ lastMessageChat(chat.CA202MENSAJE)['timEnvia'] == '' ? '' :
                                                        getDiffForHumans(lastMessageChat(chat.CA202MENSAJE)['timEnvia']) }}
                                                </small>
                                                <br>
                                                <span class="contacts-list-msg float-left" style="color: black">
                                                    {{ lastMessageChat(chat.CA202MENSAJE)['mensaje'] }}

                                                </span>
                                            </div>
                                            <!-- /.contacts-list-info -->
                                        </a>
                                    </li>
                                </template>
                            </template>
                            <!-- End Contact Item -->
                        </ul>
                        <!-- /.contacts-list -->
                    </div>
                </div>
                <widget-typing :class-name="userTyping ? 'opacity-100' : 'opacity-0'"></widget-typing>
                <!-- Formulario para enviar mensaje -->
                <div class="card-footer">
                    <form @submit.prevent="onSendMessageChat">
                        <div class="input-group">
                            <base-text-area v-model="message" textareaId="message" title=""
                                :displayCountCharacters="false" :rows="1" :errors="[]"
                                :disabled="isLoadingOperationChat || !currentChat.CA202CODCHAT"
                                :onKeydown="onSendMessageChat" :onKeyup="sendEventTyping"
                                placeholder="Escriba el mensaje ..."></base-text-area>
                            <span class="input-group-append">
                                <base-button-forms :disabled="isLoadingOperationChat || !message" :permissionId="62"
                                    classButton="btn-primary btn-sm" icon="fa-solid fa-paper-plane"></base-button-forms>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { computed, onMounted, ref, toRef, watch } from 'vue'
import Swal from 'sweetalert2';

import BaseInputForms from '@/common/forms/BaseInputForms.vue'
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import WidgetTyping from '@/common/widgets/WidgetTyping.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';


import useChat from '@/modules/chat/composables/useChat'
import store from '@/store';



export default {

    components: {
        BaseInputForms,
        BaseButtonForms,
        WidgetLoading,
        BaseSelectForms,
        BaseSelectList,
        WidgetTyping,
        BaseTextArea
    },
    props: {
        chatParameters: {
            type: Object,
            required: true,
            default: () => ({
                tipoAsunto: '',
                asunto: '',
                codTipoAsunto: '',
                destinatario: '',
            }),

        }
    },

    setup(props) {
        const {
            loadChatListStandar,
            chatList,
            chatStatus,
            chatMessage,
            sendMessageChat,
            getChatMessage,
            getDiffForHumans,
            currentChat,
            containerMessageList,
            isLoadingOperationChat,
            isLoading,
            lastMessageChat,
            startChat,
            closeChat,
            updateChatList,
            userDataLoggedIn,
            userTyping,
            sendEventTyping,
            getChatsNavbar,
            isOwnChat
        } = useChat()

        const message = ref(null)
        const chatParametersCopy = toRef(props, 'chatParameters')
        const isChatCollapsed = ref(true)

        watch(chatParametersCopy, async (newValue) => {
            new Swal({
                title: 'Iniciando Chat...',
                allowOutsideClick: false
            })
            Swal.showLoading()
            const { response } = await startChat(
                chatParametersCopy.value
            )
            // Abrir chat
            if (response) {
                toggleChat()
                currentChat.value = chatList.value[0]
                getChatMessage(currentChat.value)
                Swal.close()
                return
            }

        },
            { deep: true }
        )
        // Conectar a websockets
        window.Echo
            .private(`chat-standar.${userDataLoggedIn.value.id}`)
            .listen('.ChatEvent', (e) => {
                updateChatList(e)
            })
        window.Echo
            .private(`chat-escribiendo`)
            .listenForWhisper('typing', (e) => {
                if (e.asunto === currentChat.value.CA202ASUNTO) {
                    userTyping.value = true
                    setTimeout(() => {
                        userTyping.value = false
                    }, 2000)
                }

            })

        const toggleChat = () => {
            isChatCollapsed.value = !isChatCollapsed.value
        }

        const callLoadList = async () => {
            await loadChatListStandar({})
            currentChat.value = chatList.value[0]
            getChatMessage(currentChat.value)

        }
        callLoadList()

        return {
            chatList,
            chatStatus,
            chatMessage,
            userDataLoggedIn,
            currentChat,
            containerMessageList,
            message,
            isLoadingOperationChat,
            isLoading,
            closeChat,
            userTyping,
            isOwnChat,

            isChatCollapsed,
            lastMessageChat,
            getChatMessage,
            getDiffForHumans,
            callLoadList,
            sendEventTyping,
            toggleChat,

            onSendMessageChat: async () => {
                const { response, send, chat } = await sendMessageChat(currentChat.value.CA202CODCHAT, {
                    mensaje: message.value
                })
                if (response && send) {
                    getChatMessage(chat)
                    message.value = ''
                    getChatsNavbar()
                }
                document.getElementById('message').focus()

            },
            onCloseChat: async (chatId) => {
                const { isConfirmed } = await Swal.fire({
                    title: '¿Esta seguro?',
                    text: '¿Desea cerrar el chat?',
                    showDenyButton: true,
                    confirmButtonText: 'Sí'
                })
                if (isConfirmed) {
                    new Swal({
                        title: 'Cerrando chat',
                        allowOutsideClick: false
                    })
                    Swal.showLoading()
                    const { response } = await closeChat(chatId)
                    if (response) {
                        currentChat.value = []
                        getChatMessage(currentChat.value)
                        Swal.fire('Chat cerrado', '', 'success')
                        if (chatList.value.length === 0) {
                            toggleChat()
                        }
                        return
                    }
                    Swal.fire('Ha ocurrido un error', '', 'error')
                }

            },



        }
    }






}
</script>
<style lang="css" scoped src="../../dashboard/css/adminlte.css"></style>
<style lang="scss" scoped>
@import "@/styles/styles.scss";

/* Card*/
.card-info {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: none;
}

.card-title {
    white-space: nowrap;
    /* Evita que el texto se divida en varias líneas */
    overflow: hidden;
    /* Oculta el texto que no cabe en el contenedor */
    text-overflow: ellipsis;
    /* Agrega "..." al final del texto truncado */
    max-width: 69%;
    /* Ajusta el ancho máximo del elemento */
}

.card-header {
    background-color: #2A3F54;
    /* Azul oscuro */
    color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.card-body {
    background-color: #f4f6f9;
    /* Fondo claro para un look moderno */
    padding: 15px;
}

.card-footer {
    background-color: #ffffff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px;
}

/* Chat */
.direct-chat-text {
    border-radius: 20px;
    padding: 10px 15px;
    font-size: 14px;
    background-color: #dfe6e9;
    /* Color neutro claro */
    color: #2d3436;
}

.direct-chat-text.bg-danger {
    background-color: #e74c3c;
    /* Rojo más suave */
}

.direct-chat-text.bg-info {
    background-color: #3498db;
    /* Azul claro */
    color: white;
}

/**Botones de accion */
.btn-tool {
    color: #fff;
}

.btn-tool:hover {
    color: #3498db;
}

/*Input */

.input-group-append button {
    border-radius: 0 20px 20px 0;
    background-color: #3498db;
    color: white;
}


/*Fijar chat*/

.fixed-chat {
    position: fixed;
    bottom: 0;
    right: 5px;
    transform-origin: bottom right;
    transition: 0.5s;

}

/**Animación */
.fade-enter-active,
.fade-leave-active {
    transition: transform .5s;
}

.fade-enter-from,
.fade-leave-to {
    transform: scale(0.5);
}

.card-deck {
    max-width: 415px;
}

/**Boton flotante */
.floating-btn {
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: none;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    z-index: 2;
    transition: 0.5s;
}

.floating-btn:hover {
    transform: scale(1.3);
    transition: 0.5s;
}
</style>