import { useStore } from "vuex";

import requestApi from "@/api/requestApi"
import { checkFile } from "@/helpers/checkfile";
import { setErrorsFields } from '@/helpers/errorsFields'




export const loadFilesList = async ({ commit }, formData) => {
    try {
        commit('clearFilesList')
        commit('showProcessHideAlert', 'filesList')
        const { data } = await requestApi.get(`/archivo/index`, {
            params: formData
        })
        // Ciclo para validar si la imagen existe o no
        for (const key in data.archivos.tiposArchivos) {
            const check = await checkFileExist({ commit }, data.archivos.tiposArchivos[key].rutaImagen)
            if (check.response) {
                data.archivos.tiposArchivos[key].existeImagen = true
            } else {
                data.archivos.tiposArchivos[key].existeImagen = false
            }
        }
        commit('setFilesList', { data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
        return setErrorsFields({ modulo: 'files', error: error })

    }

}
export const uploadFile = async ({ commit }, { formData, fromCamera }) => {
    try {
        let uri = fromCamera ? `/archivo/storeCamara` : `/archivo/store`
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(uri, formData)
        commit('hideProcessShowAlert', { messageServer: 'Archivo subido correctamente', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data }
    } catch (error) {
        return setErrorsFields({ modulo: 'files', error: error })

    }

}

export const checkFileExist = async ({ commit }, url) => {
    try {
        commit('showProcessHideAlert', 'checking')
        await checkFile(url)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
        return { response: false }

    }
}

export const validateFile = async ({ commit }, formData) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/archivo/validate`, formData)
        commit('updateFilesValidate', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
        console.log(error);
        return setErrorsFields({ modulo: 'files', error: error })


    }
}

export const deleteFile = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/archivo/destroy`, formData)
        // commit('deleteFileList',{codArchivo:formData.tipoArchivo})
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }

    } catch (error) {
        console.log(error);
        return setErrorsFields({ modulo: 'files', error: error })


    }
}


