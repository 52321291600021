<template>
  <div class="wrapper" @click="hideSidebar">

    <TheNavbar />
    <TheSidebar />


    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper"  style="background-color: #eee;" >
      <router-view v-slot="{ Component, route }">
        <!-- <keep-alive> -->
        <component :is="Component" :key="route.name" />
        <!-- </keep-alive> -->
      </router-view>
    </div>
    <!-- /.content-wrapper -->

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-light">
      <!-- Control sidebar content goes here -->
      <div class="p-3 usuarios-activos">
        <h5>Usuarios Activos</h5>
        <ul>
          <li v-tippy="userDataLoggedIn.name">
            <TheUserAvatar :name="userDataLoggedIn.name" />
            <span class="p-1"> {{ shortUserName(userDataLoggedIn.name) }}(Yo)</span>
            <!-- -{{ userDataLoggedIn.perfilDescripcion }}  -->
          </li>
          <div class="dropdown-divider"></div>
          <template v-for="user in activeUsers" :key="user.id">
            <li  v-tippy="user.name">
              <TheUserAvatar :name="user.name" />
              {{ shortUserName(user.name) }}
              <!-- -{{ user.role }} -->
            </li>
            <div class="dropdown-divider"></div>
          </template>
        </ul>
      </div>
    </aside>
    <!-- /.control-sidebar -->

    <!-- Main Footer -->
    <footer class="main-footer">
      <!-- To the right -->
      <div class="float-right d-none d-sm-inline">
      <small>Anything you want</small>
      </div>
      <!-- Default to the left -->
      <small>Copyright &copy; 2021-2023 <a href="https://nagoware.com/website/" target="_blank">BizzyPay</a>.</small>
    </footer>
    <!-- Componente para cerrar cesión por inactividad -->
    <TheIdle />
  </div>
  <!-- ./wrapper -->
</template>

<script>
import { ref } from 'vue';
import useAuth from '@/modules/auth/composables/useAuth';
import TheNavbar from '@/modules/dashboard/components/TheNavbar.vue';
import TheSidebar from '@/modules/dashboard/components/TheSidebar.vue';
import TheIdle from '../components/TheIdle.vue';
import TheUserAvatar from '@/modules/dashboard/components/TheUserAvatar.vue';
import { directive } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import useDashboard from '@/modules/dashboard/composables/useDashboard';


export default {
  components: { TheNavbar, TheSidebar, TheIdle, TheUserAvatar },
  directives: {
    tippy: directive,
  },
  setup() {
    const { hideSidebar } = useDashboard()
    const { userDataLoggedIn } = useAuth()

    const activeUsers = ref([])

    const orderUsers = () => activeUsers.value.sort((x, y) => x.name.localeCompare(y.name))


    // Conectarse a canal sesion
    window.Echo.join(`session`)
      .here((users) => {
        activeUsers.value = users.filter(u => u.id != userDataLoggedIn.value.id)
        orderUsers()
      })
      .joining((user) => {
        activeUsers.value.push(user)
        orderUsers()
      })
      .leaving((user) => {
        activeUsers.value = activeUsers.value.filter(u => u.id != user.id)
      })
      .error((error) => {
        console.error(error);
      });

    return {
      hideSidebar,
      shortUserName: (name = '') => {
        if (name.length > 15) {
          return name.slice(0, 15) + '...'
        }
        return name;

      },

      userDataLoggedIn,
      activeUsers

    }

  },
}
</script>
<style lang="css" scoped src="../css/adminlte.css"></style>
<style scoped>
.usuarios-activos ul {
  list-style: none;
  padding: 0;
}

.usuarios-activos li {
  padding-top: 0.3rem;
  transition-property: color font-weight;
  transition-duration: .1s
}

.usuarios-activos li:hover {
  color: black;
  font-weight: 700;
}

.wrapper{
  overflow: hidden;
}
</style>
