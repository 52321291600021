<template>
  <TheContentHeader titlePage="Chats" />
  <the-content-body>
    <!-- Filtros -->
    <form @submit.prevent="callLoadList">
      <div class="form-group row mb-4">
        <div class="col-lg-3 col-md-4 col-sm-6">
          <base-select-forms v-model="formData.tipoAsunto" title="Tipo Asunto" idSelect="tipoAsunto"
            :options="chatParameters['asuntos']" optionName="nombreAsunto" optionId="tipoAsunto"
            :errors="[]"></base-select-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <base-select-forms v-model="formData.estadoAsunto" title="Estado" idSelect="estadoAsunto"
            :options="[{ nombre: 'Abierto', codigo: 'A' }, { nombre: 'Cerrado', codigo: 'C' }]" optionName="nombre"
            optionId="codigo" :errors="[]"></base-select-forms>
        </div>
        <div v-if="checkGroupUser('usuario-shivam', userDataLoggedIn.perfil)" class="col-lg-3 col-md-4 col-sm-6">
          <base-select-forms v-model="formData.codAliado" title="Aliado" idSelect="codAliado"
            :options="simpleListAllied" optionName="nombreAliado" optionId="ca100CodAliado" :errors="[]"
            :onChange="callgetUserAndShop"
            :paramFunction="formData.codAliado ? formData.codAliado : '%%'"></base-select-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <base-select-forms v-model="formData.codAlmacen" title="Almacén" idSelect="codAlmacen" :options="shopList"
            optionName="Ca110Alias" optionId="Ca110CodAlmacen" :onChange="callgetUser" :errors="[]"></base-select-forms>
        </div>
        <div v-if="checkGroupUser('usuario-shivam', userDataLoggedIn.perfil)" class="col-lg-3 col-md-4 col-sm-6">
          <base-select-forms v-model="formData.codUsuarioAlmacen" title="Usuarios" idSelect="codUsuarioAlmacen"
            :options="userShopChat" optionName="name" optionId="id" :errors="[]"></base-select-forms>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <b class="col-form-label">Fechas</b>
          <div class="input-group">
            <input v-model="formData.fechaInicial" type="date" class="form-control form-control-sm">
            <input v-model="formData.fechaFinal" type="date" class="form-control form-control-sm">
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6">
          <base-button-forms label="Buscar" classButton="btn-primary mt-3" :disabled="isLoading" :isLoading="isLoading"
            :permissionId="61"></base-button-forms>
        </div>
      </div>
    </form>

    <!-- Chats -->
    <div class="card border-0 shadow-sm mb-4">
      <div class="card-body p-4">
        <div class="row g-0">
          <!-- Lista de asuntos -->
          <div class="col-12 col-lg-4 col-xl-3 border-end pe-3 chat-list">
            <div class="mb-3 text-center">
              <h5 class="text-muted">Asuntos</h5>
              <div class="d-flex justify-content-around">
                <span class="badge bg-success"><i class="fa fa-flag"></i> Atendidas: {{ chatStatus[0] }}</span>
                <span class="badge bg-warning"><i class="fa fa-flag"></i> Pendientes: {{ chatStatus[1] }}</span>
              </div>
            </div>
            <widget-loading v-if="isLoading" />

            <template v-else>
              <a v-for="chat in chatList" :key="chat.CA202CODCHAT"
                class="list-group-item list-group-item-action mt-2 p-3 rounded shadow-sm"
                :class="{ 'active-chat': currentChat.CA202CODCHAT === chat.CA202CODCHAT }"
                @click="getChatMessage(chat)">
                <div class="badge float-start"
                  :class="{ 'bg-warning': chat.CA202FLAG == 1, 'bg-success': chat.CA202FLAG == 0 }">
                  <i class="fa fa-flag"></i>
                </div>
                <div class="ms-3">
                  <strong>{{ chat.CA202ASUNTO }}</strong>
                  <div class="small text-muted">
                    {{ lastMessageChat(chat.CA202MENSAJE)['mensaje'] }}
                    <br>
                    {{ getDiffForHumans(lastMessageChat(chat.CA202MENSAJE)['timEnvia']) }}
                  </div>
                </div>
              </a>
            </template>
          </div>

          <div class="col-12 col-lg-8 col-xl-9 ps-3">
            <div class="d-flex align-items-center border-bottom mb-3 pb-2">
              <strong>Asunto: {{ currentChat.CA202ASUNTO }}</strong>
            </div>
            <!-- Lista de mensajes -->
            <div class="card border-0 shadow-sm mb-4">
              <div class="chat-messages p-4" ref="containerMessageList">
                <template v-for="(message, index) in chatMessage" :key="index">
                  <div v-if="message['envia'] === userDataLoggedIn.id" class="chat-message-right pb-4">
                    <div class="text-muted small text-end">
                      {{ getDiffForHumans(message['timEnvia']) }}
                    </div>
                    <div class="message-bubble-right">
                      <div class="font-weight-bold mb-1">{{ message['nombreEnvia'] }}</div>
                      <div class="text-break">{{ message['mensaje'] }}</div>
                    </div>
                  </div>
                  <div v-else class="chat-message-left pb-4">
                    <div class="text-muted small">
                      {{ getDiffForHumans(message['timEnvia']) }}
                    </div>
                    <div class="message-bubble-left">
                      <div class="font-weight-bold mb-1">{{ message['nombreEnvia'] }}</div>
                      <div class="text-break">{{ message['mensaje'] }}</div>
                    </div>
                  </div>
                </template>
                <widget-typing :class-name="userTyping ? 'opacity-100' : 'opacity-0'"></widget-typing>
              </div>
            </div>
            <!-- Formulario para enviar mensaje -->
            <div class="card-footer bg-white border-0 p-3">
              <form @submit.prevent="onSendMessageChat">
                <div class="input-group">
                  <base-text-area v-model="message" textareaId="message" title="" :displayCountCharacters="false"
                    :rows="1" :errors="[]" :disabled="isLoadingOperationChat" :onKeydown="onSendMessageChat"
                    :onKeyup="sendEventTyping" placeholder="Escriba el mensaje ..."></base-text-area>
                  <button class="btn btn-primary btn-sm ms-2" :disabled="isLoadingOperationChat || !message">
                    <i class="fa-solid fa-paper-plane"></i> Enviar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </the-content-body>
</template>

<script>
import { computed, onMounted, ref } from 'vue'

import BaseInputForms from '@/common/forms/BaseInputForms.vue'
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetTyping from '@/common/widgets/WidgetTyping.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';


import useChat from '@/modules/chat/composables/useChat'
import useAllied from '@/modules/allied/composables/useAllied';
import useShop from '@/modules/shop/composables/useShop';
import useUsers from '@/modules/users/composables/useUsers';



export default {

  components: {
    BaseInputForms,
    BaseButtonForms,
    WidgetLoading,
    BaseSelectForms,
    BaseSelectList,
    TheContentBody,
    TheContentHeader,
    WidgetTyping,
    BaseTextArea
  },

  setup() {
    const {
      loadChatListAdmin,
      chatList,
      chatStatus,
      chatMessage,
      sendMessageChat,
      getChatMessage,
      getDiffForHumans,
      currentChat,
      containerMessageList,
      isLoadingOperationChat,
      isLoading,
      lastMessageChat,
      userDataLoggedIn,
      sendEventTyping,
      userTyping,
      updateChatList,
      getChatsNavbar,
      checkGroupUser,
      chatParameters,

    } = useChat()
    const { getSimpleList: getAlliedList, simpleListAllied } = useAllied()
    const { getSimpleList: getShopList, simpleList: shopList } = useShop()
    const { getUserShopChat, userShopChat } = useUsers()
    const today = new Date().toISOString().split('T')[0];
    // const today = Intl.DateTimeFormat('en').format(new Date(Date.now()));
    // console.log(today);
    const formData = ref({
      tipoAsunto: '',
      estadoAsunto: 'A',
      codUsuarioAlmacen: '',
      codAliado: '',
      codAlmacen: '',
      codTipoAsunto: '',
      fechaInicial: today,
      fechaFinal: today
    })
    const message = ref(null)

    // Invocado al cargar el componente
    const callLoadList = async () => {
      formData.value.codTipoAsunto = formData.value.codAlmacen
      await loadChatListAdmin(formData.value)
      currentChat.value = chatList.value[0]
      getChatMessage(currentChat.value)

    }
    // Invocado por el select aliado|
    const callgetUserAndShop = () => {
      getShopList(formData.value.codAliado)
      getUserShopChat(formData.value.codAliado, formData.value.codAlmacen)
    }
    // Invocado por el select almacen
    const callgetUser = () => {
      getUserShopChat(formData.value.codAliado, formData.value.codAlmacen)
    }

    callLoadList()

    onMounted(() => {
      if (checkGroupUser('usuario-shivam', userDataLoggedIn.value.perfil)) {
        getAlliedList()
        callgetUserAndShop()
        window.Echo
          .private(`chat-shivam`)
          .listen('.ChatEvent', (e) => {
            updateChatList(e)
          })
      }
      if (checkGroupUser('usuario-almacen', userDataLoggedIn.value.perfil)) {
        getShopList(0)
        window.Echo
          .private(`chat-almacen`)
          .listen('.ChatEvent', (e) => {
            updateChatList(e)
          })
      }

    })

    // Transmitir que esta escribiendo
    window.Echo
      .private(`chat-escribiendo`)
      .listenForWhisper('typing', (e) => {
        if (e.asunto === currentChat.value.CA202ASUNTO) {
          userTyping.value = true
          setTimeout(() => {
            userTyping.value = false
          }, 2000)
        }

      })


    return {
      chatList,
      chatParameters,
      chatStatus,
      chatMessage,
      userDataLoggedIn,
      currentChat,
      containerMessageList,
      message,
      isLoadingOperationChat,
      isLoading,
      formData,
      simpleListAllied,
      shopList,
      userShopChat,
      userTyping,
      checkGroupUser,

      getShopList,
      callgetUserAndShop,
      callgetUser,
      lastMessageChat,
      getChatMessage,
      getDiffForHumans,
      callLoadList,
      sendEventTyping,
      onSendMessageChat: async () => {
        const { response, send, chat } = await sendMessageChat(currentChat.value.CA202CODCHAT, {
          mensaje: message.value
        })
        if (response && send) {
          getChatMessage(chat)
          message.value = ''
          getChatsNavbar()
        }

        document.getElementById('message').focus()
      },

    }
  }






}
</script>
<style lang="css" scoped src="../../dashboard/css/adminlte.css"></style>
<style scoped>
.chat-list {
  max-height: 600px;
  overflow-y: auto;
}

.chat-messages {
  min-height: 300px;
  max-height: 600px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message-bubble-left {
  background-color: #f8d7da;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
}

.message-bubble-right {
  background-color: #d4edda;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
}

.active-chat {
  background-color: #f0f0f0;
}

.card-footer {
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
}

input {
  font-size: 14px;
  padding: 8px;
}
</style>