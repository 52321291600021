<template>
    <TheContentHeader titlePage="Reportes" />
    <the-content-body>
        <widget-card title="Reportes" classHeader="bg-primary">
            <template v-slot:header>
                <div class="d-flex justify-content-between">
                    <div class="text-white">Reportes</div>
                </div>
            </template>


            <template v-slot:body>

                <widget-loading v-if="isLoading.reportsParameters" />

                <div v-else>
                    <div class="row justify-content-md">
                        <div class="col-12 alert-info">
                            <div class="form-group row">
                                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <base-select-forms v-model="formData.tipoReporte" title="Tipo reporte"
                                        idSelect="tipoReporte" :options="reportsParameters.tipos" optionName="nombre"
                                        optionId="codigo" :errors="alert.errorsFields"
                                        :onChange="onSelectReport"></base-select-forms>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <base-select-forms v-model="formData.codAlmacen" title="Almacenes"
                                        idSelect="codAlmacen" :options="shopAuthorizedLists" optionName="nombreAlmacen"
                                        optionId="codAlmacen" :errors="alert.errorsFields"></base-select-forms>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="formData.tipoReporte != 'PC'">
                                    <base-select-forms v-model="formData.tipoRangoFecha" title="Fecha"
                                        idSelect="tipoRangoFecha" :options="reportsParameters.rango_fecha"
                                        optionName="nombre" optionId="codigo"
                                        :errors="alert.errorsFields"></base-select-forms>
                                </div>
                                <div v-if="formData.tipoReporte == 'PC'" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                                    <base-input-forms v-model="formData.fechaCorte" inputId="fechaCorte"
                                        :errors="alert.errorsFields" title="Fecha Corte" typeInput="date"
                                        typeData="date"></base-input-forms>
                                </div>
                                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12 mt-3">
                                    <base-button-forms classButton="btn-primary" :isLoading="isLoading.reports"
                                        :disabled="isLoading.reports" label="Buscar" :permissionId="36"
                                        @click="onGetReports()"></base-button-forms>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="d-flex justify-content-end">
                            <base-button-forms v-if="dataReports.length>0" classButton="btn-primary" icon="fa-solid fa-file-export"
                                :permissionId="124" :onClick="onExportToExcel"
                                :isLoading="isLoading['salesGraphicsExport']"
                                :disabled="isLoading['salesGraphicsExport']"></base-button-forms>
                        </div>
                        <precierre-report v-if="formData.tipoReporte == 'PC'"></precierre-report>
                    </div>
                </div>
            </template>
        </widget-card>
    </the-content-body>

</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue';

import useReports from '@/modules/reports/composables/useReports'
import useShop from '@/modules/shop/composables/useShop'

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseInputCheckRadio from '@/common/forms/BaseInputCheckRadio.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import PrecierreReport from '../components/PrecierreReport.vue';


import { Toast } from "@/helpers/toastSwalert"


export default {

    components: {
        WidgetAlerts,
        WidgetSelectPaginator,
        BaseInputGroup,
        BaseButtonFab,
        WidgetCard,
        TheContentBody,
        TheContentHeader,
        WidgetDropdown,
        WidgetLoading,
        WidgetModalBoostrap,
        BaseTableForms,
        BaseButtonForms,
        BaseInputForms,
        BaseInputCheckRadio,
        BaseSelectForms,
        PrecierreReport

    },

    setup() {

        const formData = ref({
            codAlmacen: '',
            tipoReporte: '',
            tipoRangoFecha: 0,
            fechaCorte: '',
        })
        const store = useStore();

        const {
            isLoading,
            getListConfig,
            reportsParameters,
            alert,
            getDataReports,
            dataReports,
            exportToExcel
        } = useReports()
        const { getShopAuthorizedLists, shopAuthorizedLists } = useShop()

        getListConfig();
        getShopAuthorizedLists()
        store.state['reports']['dataReports'] = []

        return {
            isLoading,
            alert,
            reportsParameters,
            formData,
            shopAuthorizedLists,
            dataReports,

            onSelectReport: () => {
                store.state['reports']['dataReports'] = []
            },


            onGetReports: async () => {
                const { response } = await getDataReports(formData.value, 'precierre')
                if (response && formData.value.tipoReporte == 'PC') {
                    return
                }
            },
            onExportToExcel: async () => {
                await exportToExcel(formData.value, 'precierre')
            }
        }

    }
}
</script>

<style></style>