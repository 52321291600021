<template>
    <label :for="textareaId" class="fw-bold">{{ title }}</label>
    <textarea v-model="textAreaModel" :name="textareaId" :id="textareaId" class="form-control form-control-sm"
        :class="{ 'is-invalid': errors[textareaId] }" cols="130" :rows="rows" maxlength="200" ref="textarea"
        @blur="onBlur(paramFunction)" :disabled="disabled" @input="autoResize" @keyup="onKeyup"
        @keydown.enter.prevent="onKeydown"
        :placeholder="placeholder"></textarea>
    <div v-if="displayCountCharacters">
        {{ countCharacters }} Caracteres
    </div>
    <span v-if="errors[textareaId]" class="invalid-feedback" role="alert">
        <small>{{ errors[textareaId] ? errors[textareaId][0] : '' }}</small>
    </span>
</template>
<script>
import { computed, ref, onMounted, useTemplateRef } from 'vue'
export default {
    props: {
        modelValue: {
            required: false,
            default: '',
            type: String,
        },
        textareaName: {
            type: String
        },
        textareaId: {
            type: String
        },
        title: {
            type: String
        },
        errors: {
            type: Object,
            default: []
        },
        rows: {
            type: Number,
            default: 1
        },
        onBlur: {
            type: Function,
            default: () => { }
        },
        onKeyup: {
            type: Function,
            default: () => { }
        },
        onKeydown: {
            type: Function,
            default: () => { }
        },
        paramFunction: {
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        displayCountCharacters: {
            type: Boolean,
            default: true
        },
        placeholder:{
            type:String,
            default:''
        },
    },
    setup(props, { emit }) {

        const textarea = useTemplateRef('textarea')
        const textAreaModel = computed({
            get() {
                return props.modelValue
            },
            set(newValue) {
                emit('update:modelValue', newValue)
            }
        })

        const autoResize = () => {
            const el = textarea.value;
            el.style.height = 'auto'; // Resetea la altura
            el.style.height = `${el.scrollHeight}px`; // Ajusta la altura al contenido
        }
        onMounted(() => {
            autoResize(); // Ajustar el tamaño inicial si hay contenido
        });
        return {
            textAreaModel,
            autoResize,
            countCharacters: computed(() => 200 - props.modelValue.length),




        }
    }

}
</script>

<style></style>