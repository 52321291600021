<template>
    <TheContentHeader titlePage="Mis tareas" />
    <the-content-body>
        <widget-card title="Tareas" classHeader="bg-primary">
            <template v-slot:header>
                <div class="d-flex justify-content-between">
                    <div class="text-white">Tareas</div>
                    <base-button-fab @on:click="openModal('newTask')" :permissionId="117"></base-button-fab>
                </div>
            </template>
            <template v-slot:body>
                <!-- Resumen mes -->
                <!-- <task-summary></task-summary> -->
                <!-- Filtros de búsqueda -->
                <form @submit.prevent="callLoadTasksIndex" class="mb-2">
                    <div class="form-group row">
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <base-input-forms v-model="formDataFilter.nombre" title="Título tarea"
                                :errors="alert.errorsFields" inputId="nombre"></base-input-forms>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <base-select-forms v-model="formDataFilter.codCategoria" title="Categoría"
                                idSelect="codCategoria" :options="categorias" optionName="CA129NOMBRE"
                                optionId="CA129CODCATEGORIA" :errors="alert.errorsFields"></base-select-forms>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <base-select-forms v-model="formDataFilter.codUsuarioAsignado" title="Asignado a"
                                idSelect="frecuencia" :options="contactosFrecuentes" optionName="nombre"
                                optionId="codigo" :errors="alert.errorsFields"></base-select-forms>
                        </div>
                        <div class="col-lg-2 col-md-3 col-sm-4 col-xs-12">
                            <base-select-forms v-model="formDataFilter.prioridad" title="Prioridad" idSelect="prioridad"
                                :options="taskParameters.prioridad" optionName="nombre" optionId="codigo"
                                :errors="alert.errorsFields"></base-select-forms>
                        </div>

                    </div>
                    <div class="form-group row mb-3">
                        <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                            <base-select-forms v-model="formDataFilter.estado" title="Estado" idSelect="estado"
                                :options="taskParameters.estados" optionName="nombre" optionId="codigo"
                                :errors="alert.errorsFields"></base-select-forms>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                            <base-input-forms v-model="formDataFilter.fechaInicio" :errors="alert.errorsFields"
                                title="Inicio" inputId="fechaInicio" typeInput="date"></base-input-forms>

                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <base-button-forms classButton="btn-primary" :isLoading="isLoading.list"
                            :disabled="isLoading.list" label="Buscar" :permissionId="116"></base-button-forms>
                    </div>
                </form>
                <widget-loading v-if="isLoading.list" />
                <template v-else-if="tasksList.length > 0">
                    <!-- <widget-select-paginator v-model="numPages"
                        :onChange="callLoadRolesIndex"></widget-select-paginator> -->
                    <base-table-forms :data="tasksList" :headers="headers" :whitPagination="false"
                        :orderColumn="formDataFilter.column" :order="formDataFilter.order" :whitOrder="true"
                        :dataFunction="orderList">
                        <tr v-for="task in tasksList" :key="task.CA126CODTAREA"
                            @dblclick="openModal(task.CA126CODTAREA.toString())" role="button">
                            <td class="text-center">{{ task.CA126CODTAREA }}</td>
                            <td class="text-center">{{ task.categoria.CA129NOMBRE }}</td>
                            <td class="text-center">{{ task.CA126NOMBRE }}</td>
                            <td class="text-center">{{ task.usuario_creador.alias }}</td>
                            <td class="text-center">{{ task.usuario_asignado.alias }}</td>
                            <td class="text-center">{{ inputDate(task.CA126FECHAINICIOESTIMADO, 2) }}</td>
                            <td class="text-center">{{ fechaEjecucion(task) }}</td>
                            <td class="text-center">{{ taskParameters.estadosBackend[task.CA126ESTADO] }}</td>
                            <td class="text-center">
                                <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
                                    { label: 'Editar', action: () => { openModal(task.CA126CODTAREA.toString()) }, permissionId: 117, visible: true },
                                    { label: 'Iniciar chat', action: () => { setChatParameteres(task) }, permissionId: 38, visible: true },
                                ]"></widget-dropdown>
                            </td>
                        </tr>
                    </base-table-forms>
                </template>
                <div v-else>
                    <div class="row justify-content-md-center">
                        <div class="col-12 alert-info text-center mt-5">
                            No hay Información a mostrar
                        </div>
                    </div>
                </div>
            </template>
        </widget-card>
        <!-- Chat -->
        <floating-chat :chatParameters="chatParameteres"></floating-chat>
    </the-content-body>
    <!-- Modal Rol -->
    <widget-modal-boostrap modalId="modalTask"
        :modalTitle="taskId === 'newTask' ? 'Crear tarea' : 'Actualizar tarea - ' + task.nombre"
        modalClassSize="modal-lg modal-dialog-scrollable" :showFooter="false" :modalStatic="'static'">
        <template v-slot:modalBody>

            <task-form :typeOperation="taskId" @on:createdTask="openModal"></task-form>

        </template>
    </widget-modal-boostrap>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex'
import { Modal } from 'bootstrap';

import useTasks from '@/modules/tasks/composables/useTasks'
import useAuth from '@/modules/auth/composables/useAuth'
import { Toast } from "@/helpers/toastSwalert"
import inputMaskFormat from "@/helpers/inputMaskFormat";


import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputCheckRadio from '@/common/forms/BaseInputCheckRadio.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseInputGroup from '@/common/forms/BaseInputGroup.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import FloatingChat from '@/modules/chat/components/FloatingChat.vue';
import TaskForm from '@/modules/tasks/components/TaskForm.vue';
import TaskSummary from '@/modules/tasks/components/TaskSummary.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';




export default {

    components: {
        BaseButtonFab,
        BaseButtonForms,
        BaseInputCheckRadio,
        BaseInputForms,
        BaseInputGroup,
        BaseSelectForms,
        BaseTableForms,
        TaskForm,
        TaskSummary,
        TheContentBody,
        TheContentHeader,
        WidgetAlerts,
        WidgetCard,
        WidgetDropdown,
        WidgetLoading,
        WidgetModalBoostrap,
        WidgetSelectPaginator,
        FloatingChat
    },

    props: {
        codTarea: {
            type: String,
        }

    },

    setup(props) {

        const taskId = ref()
        const modals = ref({ modalTask: null })
        const formDataFilter = ref({
            codTarea: 0,
            codCategoria: 0,
            codUsuarioAsignado: 0,
            prioridad: '',
            nombre: '',
            estado: '',
            fechaInicio: '',
            // fechaFin: '',
            frecuenciaEjecucion: '',
            numPages: '5',
            page: '1',
            column: 'CA126CODTAREA',
            order: 'asc'

        })
        const chatParameteres = ref({})
        const headers = ref(
            [
                { encabezado: 'ID', columna: 'CA126CODTAREA' },
                { encabezado: 'Categoría', columna: 'CA126CODCATEGORIA' },
                { encabezado: 'Nombre', columna: 'CA126NOMBRE' },
                { encabezado: 'Creador', columna: 'CA126CODUSUARIOCREADOR' },
                { encabezado: 'Asignado', columna: 'CA126CODUSUARIOASIGNADO' },
                { encabezado: 'Inicio', columna: 'CA126FECHAINICIOESTIMADO' },
                { encabezado: 'Final', columna: 'CA126FECHAEJECUCIONESTIMADA' },
                { encabezado: 'Estado', columna: 'CA126ESTADO' },
                { encabezado: 'Acciones', columna: null }]
        )
        const store = useStore()
        const {
            loadTasksList,
            tasksList,
            alert,
            isLoading,
            getTask,
            createTask,
            updateTask,
            taskParameters,
            task,
            getListConfig,
            contactosFrecuentes,
            categorias
        } = useTasks()
        const { userDataLoggedIn } = useAuth()
        const { inputDate } = inputMaskFormat();

        onMounted(() => {
            modals.modalTask = new Modal('#modalTask', {})
            if (props.codTarea) {
                openModal(props.codTarea)
            }
        })

        const callLoadTasksIndex = () => {
            loadTasksList(formDataFilter.value)
        }
        const orderList = (page, column) => {
            formDataFilter.value.page = page

            if (column) {
                formDataFilter.value.column = column
                formDataFilter.value.order = formDataFilter.value.order == 'asc' ? 'desc' : 'asc'
            }
            store.commit('tasks/orderList', { column, order: formDataFilter.value.order })
        }
        callLoadTasksIndex()

        const openModal = async (id) => {
            store.commit('tasks/clearTask')
            taskId.value = id
            modals.modalTask.show()
            if (id != 'newTask') {
                await getTask(id)
                return
            }
            await getListConfig()
        }
        const closeModal = () => {
            modals.modalTask.hide()
        }



        return {
            tasksList,
            taskId,
            alert,
            isLoading,
            task,
            openModal,
            closeModal,
            callLoadTasksIndex,
            taskParameters,
            inputDate,
            contactosFrecuentes,
            categorias,
            formDataFilter,
            headers,
            orderList,
            chatParameteres,

            fechaEjecucion: (task) => {
                if (task.CA126FECHAEJECUCIONREALTIM != '') {
                    return inputDate(new Date(task.CA126FECHAEJECUCIONREALTIM), 1)
                }
                return inputDate(task.CA126FECHAEJECUCIONESTIMADA, 2)
            },
            setChatParameteres: (task) => {
                let destinatario
                if (task.CA126CODUSUARIOCREADOR == userDataLoggedIn.value.id && task.CA126CODUSUARIOASIGNADO != userDataLoggedIn.value.id) {
                    destinatario = task.CA126CODUSUARIOASIGNADO
                } else if (task.CA126CODUSUARIOCREADOR != userDataLoggedIn.value.id && task.CA126CODUSUARIOASIGNADO == userDataLoggedIn.value.id) {
                    destinatario = task.CA126CODUSUARIOCREADOR
                }

                chatParameteres.value = {
                    tipoAsunto: 7,
                    asunto: `Tarea_${task.CA126CODTAREA}`,
                    codTipoAsunto: task.CA126CODTAREA,
                    destinatario: destinatario,
                }


            }

        }

    }
}
</script>

<style></style>