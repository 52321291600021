import { createStore } from 'vuex'

import allied from '@/modules/allied/store'
import auth from '@/modules/auth/store'
import casher from '@/modules/casher/store'
import centersInformation from '@/modules/centers-information/store'
import chat from '@/modules/chat/store'
import customer from '@/modules/customer/store'
import files from '@/modules/files/store'
import reports from '@/modules/reports/store'
import notifications from '@/modules/notification/store'
import objetives from '@/modules/parameterization/objetives/store'
import parameterization from '@/modules/parameterization/lists/store'
import product from '@/modules/product/store'
import questions from '@/modules/parameterization/questions/store'
import reference from '@/modules/references/store'
import relationship from '@/modules/parameterization/relationship/store'
import request from '@/modules/credit-request/store'
import roles from '@/modules/roles/store'
import shop from '@/modules/shop/store'
import studyRequest from '@/modules/credit-study/store'
import users from '@/modules/users/store'
import tasks from '@/modules/tasks/store'

export default createStore({
  modules: {
    allied,
    auth,
    casher,
    centersInformation,
    chat,
    customer,
    files,
    reports,
    notifications,
    objetives,
    parameterization,
    product,
    questions,
    reference,
    relationship,
    request,
    roles,
    shop,
    studyRequest,
    users,
    tasks,
  }
})
