<template>
    <base-table-forms :data="dataReports" :headers="headers" :whitPagination="false" :orderColumn="column"
        :order="order" :whitOrder="true" :dataFunction="orderList">
        <tr v-for="report in dataReports" :key="report">
            <td class="text-center">{{ report['beneficiario'] }}</td>
            <td class="text-start">{{ report['nombreBeneficiario'] }}</td>
            <td class="text-center">{{ report['CodigoCompra'] }}</td>
            <td class="text-center">{{ report['CodigoComprobante'] }}</td>
            <td class="text-center">{{ report['Numerocomprobante'] }}</td>
            <td class="text-center">{{ report['CuentaContable'] }}</td>
            <td class="text-center">{{ report['DescripcionTrxn'] }}</td>
            <td class="text-end">{{ currentFormat(report['Debito']) }}</td>
            <td class="text-end">{{ currentFormat(report['Credito']) }}</td>
            <!-- <td class="text-center">{{ currentFormat(report['Debito'] - report['Credito']) }}</td> -->

            <!-- <td class="text-center">{{ inputDate(report['fechaPartida'], 2) }}</td>
            <td class="text-center">{{ report['DescripcionPartida'] }}</td>
            <td class="text-center">{{ report['DescripcionTrxn'] }}</td>
            <td class="text-center">{{ currentFormat(report['Debito']) }}</td>
            <td class="text-center">{{ currentFormat(report['Credito']) }}</td>
            <td class="text-center">{{ currentFormat(report['Debito'] - report['Credito']) }}</td> -->



            <!-- <td class="text-center">{{ report['Empresa'] }}</td>
            <td class="text-center">{{ report['CuentaContable'] }}</td>
            <td class="text-center">{{ report['DescripcionTrxn'] }}</td>
            <td class="text-center">{{ currentFormat(report['Debito']) }}</td>
            <td class="text-center">{{ currentFormat(report['Credito']) }}</td>
            <td class="text-center">{{ inputDate(report['fechaPartida'], 2) }}</td>
            <td class="text-center">{{ report['Aux1'] }}</td>
            <td class="text-center">{{ report['Aux2'] }}</td>
            <td class="text-center">{{ report['Aux3'] }}</td>
            <td class="text-center">{{ report['CentrodeCosto'] }}</td>
            <td class="text-center">{{ report['CodigoComprobante'] }}</td>
            <td class="text-center">{{ report['Numerocomprobante'] }}</td>
            <td class="text-center">{{ report['COnsecutivoTrxngeneral'] }}</td>
            <td class="text-center">{{ report['Componente'] }}</td>
            <td class="text-center">{{ report['referenciaPartida'] }}</td>
            <td class="text-center">{{ report['DescripcionPartida'] }}</td>
            <td class="text-center">{{ report['EtiquetaReversion'] }}</td>
            <td class="text-center">{{ report['beneficiario'] }}</td>
            <td class="text-center">{{ report['CodigoCompra'] }}</td> -->

        </tr>
        <tr>
            <td class="text-center" colspan="7"></td>
            <td class="text-center">{{ calTotalDebito() }}</td>
            <td class="text-center">{{ calTotalCredito() }}</td>
            <td class="text-center">{{ currentFormat(totalCredito - totalDebito) }}</td>
        </tr>
    </base-table-forms>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import useReports from '@/modules/reports/composables/useReports'
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import { orderArray } from '@/helpers/utilities'
import inputFormat from '@/helpers/inputMaskFormat'


export default {
    components: {
        BaseTableForms
    },

    setup() {


        const {
            dataReports
        } = useReports()
        const totalDebito = ref(0)
        const totalCredito = ref(0)
        const store = useStore();
        const { inputDate, inputCurrence } = inputFormat()
        const column = ref('')
        const order = ref('')
        const headers = ref(
            [

                { encabezado: 'Beneficiario', columna: 'beneficiario' },
                { encabezado: 'Nombre beneficiario', columna: 'nombreBeneficiario' },
                { encabezado: 'Compra', columna: 'CodigoCompra' },
                { encabezado: 'Cod. Comprobante', columna: 'CodigoComprobante' },
                { encabezado: 'Numero comprobante', columna: 'Numerocomprobante' },
                { encabezado: 'Cuenta Contable', columna: 'CuentaContable' },
                { encabezado: 'Descripcion Trxn', columna: 'DescripcionTrxn' },
                { encabezado: 'Debito', columna: 'Debito' },
                { encabezado: 'Credito', columna: 'Credito' },
                // { encabezado: 'Saldo', columna: null },


                // { encabezado: 'Fecha Partida', columna: 'fechaPartida' },
                // { encabezado: 'Descripcion Partida', columna: 'DescripcionPartida' },
                // { encabezado: 'Descripcion Trxn', columna: 'DescripcionTrxn' },
                // { encabezado: 'Debito', columna: 'Debito' },
                // { encabezado: 'Credito', columna: 'Credito' },
                // { encabezado: 'Saldo', columna: null },

                // { encabezado: 'Empresa', columna: 'Empresa' },
                // { encabezado: 'Cuenta Contable', columna: 'CuentaContable' },
                // { encabezado: 'Descripcion Trxn', columna: 'DescripcionTrxn' },
                // { encabezado: 'Debito', columna: 'Debito' },
                // { encabezado: 'Credito', columna: 'Credito' },
                // { encabezado: 'Fecha Partida', columna: 'fechaPartida' },
                // { encabezado: 'Aux 1', columna: 'Aux1' },
                // { encabezado: 'Aux 2', columna: 'Aux2' },
                // { encabezado: 'Aux 3', columna: 'Aux3' },
                // { encabezado: 'Centro de Costo', columna: 'CentrodeCosto' },
                // { encabezado: 'Codigo Comprobante', columna: 'CodigoComprobante' },
                // { encabezado: 'Numero comprobante', columna: 'Numerocomprobante' },
                // { encabezado: 'Consecutivo Trxn general', columna: 'COnsecutivoTrxngeneral' },
                // { encabezado: 'Componente', columna: 'Componente' },
                // { encabezado: 'Referencia Partida', columna: 'referenciaPartida' },
                // { encabezado: 'Descripcion Partida', columna: 'DescripcionPartida' },
                // { encabezado: 'Etiqueta Reversion', columna: 'EtiquetaReversion' },
                // { encabezado: 'Beneficiario', columna: 'beneficiario' },
                // { encabezado: 'Codigo Compra', columna: 'CodigoCompra' },
            ]
        )
        const orderList = (page, col) => {
            if (col) {
                column.value = col
                order.value = order.value == 'asc' ? 'desc' : 'asc'
            }
            store.state['reports']['dataReports'] = orderArray(dataReports.value, column.value, order.value)
        }
        return {
            dataReports,
            orderList,
            column,
            order,
            headers,
            inputDate,
            currentFormat: (value) => {
                return inputCurrence(value, 0, 'state')
            },
            calTotalDebito: () => {
                let total = 0;
                dataReports.value.forEach(element => {
                    total += element['Debito']
                });
                totalDebito.value = total
                return inputCurrence(total, 0, 'state')
            },
            calTotalCredito: () => {
                let total = 0;
                dataReports.value.forEach(element => {
                    total += element['Credito']
                });
                totalCredito.value = total
                return inputCurrence(total, 0, 'state')
            },
            totalCredito,
            totalDebito
        }
    },
}
</script>