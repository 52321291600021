<template>
    <TheContentHeader titlePage="Pantalla Inicial" />
    <the-content-body>
        <div v-if="showGraphics()">
            <div class="row mb-3">
                <div v-if="checkPermission(68)" class="col-lg-12">
                    <RequestGraphics />
                </div>
            </div>
            <div class="row mb-3">
                <div v-if="checkPermission(69)" class="col-lg-6">
                    <SalesGraphics />
                </div>
                <div v-if="checkPermission(72)" class="col-lg-6">
                    <PaymentsGraphics />
                </div>
            </div>
            <div v-if="checkPermission(71)" class="row mb-3">
                <!-- <div class="col-lg-12">
                    <ShivamAccountGraphics />
                </div> -->
                <div v-if="checkPermission(70)" class="col-lg-12">
                    <CreditsGraphics />
                </div>
            </div>
        </div>
    </the-content-body>
</template>

<script>
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import RequestGraphics from '@/modules/reports/components/RequestGraphics.vue'
import SalesGraphics from '@/modules/reports/components/SalesGraphics.vue'
import PaymentsGraphics from '@/modules/reports/components/PaymentsGraphics.vue'
import CreditsGraphics from '@/modules/reports/components/CreditsGraphics.vue'
import ShivamAccountGraphics from '@/modules/reports/components/ShivamAccountGraphics.vue'
import useAuth from '@/modules/auth/composables/useAuth'



export default {
    components: {
        TheContentHeader,
        TheContentBody,
        RequestGraphics,
        SalesGraphics,
        PaymentsGraphics,
        CreditsGraphics,
        ShivamAccountGraphics,
    },
    setup() {
        const { checkGroupUser, userDataLoggedIn, checkPermission } = useAuth()

        return {
            checkPermission,
            showGraphics: () => {
                return checkGroupUser('usuario-almacen', userDataLoggedIn.value.perfil)
            }
        }



    },
}
</script>