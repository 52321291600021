import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

export const loadAlliedList = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'alliedList')
        const { data } = await requestApi.get(`/aliado/index`, {
            params: formData
        })
        commit('setAlliedList', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'allied', error: error })
    }
}
export const getSimpleList = async ({ commit }) => {
    try {
        commit('showProcessHideAlert', 'alliedList')
        const { data } = await requestApi.get(`/aliado/listadoSimple`)
        
        commit('setSimpleList', { data: data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'allied', error: error })
    }
}

export const createAllied = async ({ commit }, { formData }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/aliado/store`, formData)
        commit('addAllied', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'allied', error: error })
    }
}
export const getAllied = async ({ commit }, { alliedId }) => {
    try {
        commit('showProcessHideAlert', 'allied')
        const { data } = await requestApi.get(`/aliado/getAliado/${alliedId}`)
        commit('setAllied', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'allied', error: error })
    }
}
export const updateAllied = async ({ commit }, { formData, alliedId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/aliado/update/${alliedId}?_method=PUT`, formData)
        commit('updateAllied', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'allied', error: error })
    }
}
export const alliedStudyDecision = async ({ commit }, { formData, alliedId }) => {
    try {
        commit('showProcessHideAlert', 'operation')
        const { data } = await requestApi.post(`/aliado/decisionEstudioAliado/${alliedId}?_method=PUT`, formData)
        commit('updateAllied', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'allied', error: error })
    }
}

export const getInfoAliado = async ({ commit }, { tipoIdAliado, numIdAliado }) => {
    try {
        commit('showProcessHideAlert', false)
        const { data } = await requestApi.get(`/aliado/consultarAliadoVista?tipoIdAliado=${tipoIdAliado}&numIdAliado=${numIdAliado}`)
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true' })
        return { response: true, data: data.data }
    } catch (error) {
        return setErrorsFields({ modulo: 'allied', error: error })
    }
}



