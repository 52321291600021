<template>
  <TheContentHeader titlePage="Productos" />
  <the-content-body>
    <widget-card title="Productos" classHeader="bg-primary">
      <template v-slot:header>
        <div class="d-flex justify-content-between">
          <div class="text-white">Productos</div>
          <base-button-fab @on:click="openModal('newProduct')" :permissionId="65"></base-button-fab>
        </div>
      </template>
      <template v-slot:body>
        <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
        <!-- Filtros de búsqueda -->
        <form @submit.prevent="callLoadProductList">
          <div class="form-group row">
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-select-forms v-model="formData.codAlmacen" title="Almacenes" idSelect="codAlmacen"
                :options="shopAuthorizedLists" optionName="nombreAlmacen" optionId="codAlmacen"
                :errors="errorsFields"></base-select-forms>

            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-select-forms v-model="formData.codCategoria" title="Categoria Productos" :options="productCategorie"
                optionId="ca001CodigoItemDeLista" optionName="ca001DescripcionItemDeLista" :errors="errorsFields"
                idSelect="codCategoria"></base-select-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-input-forms v-model="formData.codReferencia" title="Referencia" :errors="errorsFields"
                inputId="codReferencia"></base-input-forms>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
              <base-select-forms v-model="formData.estadoProducto" title="Estado"
                :options="[{ nombre: 'Activo', codigo: 'A' }, { nombre: 'Inactivo', codigo: 'I' }]" optionId="codigo"
                optionName="nombre" :errors="errorsFields" idSelect="estadoProducto"></base-select-forms>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-4">
            <base-button-forms classButton="btn-primary" :isLoading="isLoading" :disabled="isLoading" label="Buscar"
              :permissionId="64"></base-button-forms>
          </div>
        </form>

        <widget-loading v-if="isLoading" />
        <template v-else-if="productList.data">
          <!-- Resultado búsqueda -->
          <widget-select-paginator v-model="formData.numPages"
            :onChange="callLoadProductList"></widget-select-paginator>
          <!-- Lista de productos -->
          <div class="contenedor-producto mt-4">
            <div class="producto" v-for="product in productList.data" :key="product.CA120CODPRODUCTO">
              <div class="producto-imagen">
                <img :src="product.thumbnail" onerror="this.src='/assets/img/notFoundImage.png'" role="button"
                  @click="openModal(product.CA120CODPRODUCTO)" alt="Product Image">
              </div>
              <div class="producto-pie">
                <div class="producto-ref">
                  <p class="texto-ref">Ref: {{ product.CA120CODREFERENCIA }}</p>
                </div>
                <div class="producto-botones">
                  <i v-if="checkPermission(66)" class="fa-solid fa-pen icono-editar" role="button"
                    @click="openModal(product.CA120CODPRODUCTO)"></i>
                  <i v-if="checkPermission(67)" class="fa-solid fa-trash icono-eliminar ms-2" role="button"
                    @click="onDelete(product)"></i>
                </div>
              </div>
            </div>
          </div>

          <div class="contenedor-paginacion">
            <Bootstrap5Pagination :data="productList" @pagination-change-page="callLoadProductList" :limit="10"
              align="left" />
              <div class="text-center">Mostrando {{ productList.data.length }} de {{ productList.total }} elementos</div>
          </div>
        </template>
        <div v-else>
          <div class="row justify-content-md-center">
            <div class="col-12 alert-info text-center mt-5">
              No hay Información a mostrar
            </div>
          </div>
        </div>
      </template>
    </widget-card>
  </the-content-body>
  <!-- Modal para crear o actualizar solicitud -->
  <widget-modal-boostrap modalId="modalProduct"
    :modalTitle="productId === 'newProduct' ? 'Crear Producto' : 'Actualizar producto No. ' + productId + ' Referencia ' + product.CA120CODREFERENCIA"
    modalClassSize="modal-dialog-scrollable modal-xl" modalStatic="static" :showFooter="false">
    <template v-slot:modalBody>
      <product-operation ref="refProductOperation" @on:createdProduct="openModal" @on:closeModal="closeModal"
        :typeOperation="productId"></product-operation>
    </template>
  </widget-modal-boostrap>
</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { Modal } from 'bootstrap';
import { useStore } from 'vuex'
import Swal from 'sweetalert2';
import { Bootstrap5Pagination } from 'laravel-vue-pagination';

import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseButtonFab from '@/common/forms/BaseButtonFab.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import ProductOperation from '../components/ProductOperation.vue';


import useProduct from '@/modules/product/composables/useProduct'
import useAuth from '@/modules/auth/composables/useAuth'
import useShop from '@/modules/shop/composables/useShop'
import useFiles from '@/modules/files/composables/useFiles'


export default {
  components: {
    WidgetAlerts,
    ProductOperation,
    BaseButtonFab,
    BaseButtonForms,
    WidgetCard,
    TheContentBody,
    TheContentHeader,
    WidgetDropdown,
    BaseInputForms,
    WidgetLoading,
    WidgetModalBoostrap,
    BaseSelectForms,
    BaseSelectList,
    WidgetSelectPaginator,
    BaseTableForms,
    Bootstrap5Pagination



  },

  setup() {
    const formData = ref({
      codAlmacen: '',
      codReferencia: '',
      codCategoria: '',
      estadoProducto: '',
      numPages: '5',
      page: ''
    })
    const store = useStore()

    const modals = ref({ modalProduct: null })
    const productId = ref()
    const refProductOperation = ref(null)
    const {
      isLoading,
      loadProductList,
      productList,
      getProduct,
      messageServer,
      showAlert,
      typeAlert,
      productParameters,
      errorsFields,
      productCategorie,
      deleteProduct,
      product

    } = useProduct()

    const { checkGroupUser, userDataLoggedIn, checkPermission } = useAuth()
    const { getShopAuthorizedLists, shopAuthorizedLists } = useShop()
    const { loadFilesList } = useFiles()


    onMounted(() => {
      modals.modalProduct = new Modal('#modalProduct', {})
      refProductOperation.value
    })
    const callLoadProductList = async (page) => {
      formData.value.page = page
      await loadProductList(formData.value)
    }
    callLoadProductList()
    getShopAuthorizedLists('producto')

    return {
      productId,
      product,
      productList,
      messageServer,
      showAlert,
      typeAlert,
      isLoading,
      productParameters,
      errorsFields,
      formData,
      productCategorie,
      shopAuthorizedLists,
      refProductOperation,


      callLoadProductList,

      openModal: async (id) => {
        store.commit('product/clearProduct')
        modals.modalProduct.show()
        productId.value = id
        refProductOperation.value.limpiarFormDataProductoAlmacen()
        refProductOperation.value.localImage = {}
        refProductOperation.value.tap = 1
        if (id != 'newProduct') {
          // Consultar producto
          await getProduct(id)
          // Obtener listado de archivos
          await loadFilesList({
            tipoModelo: 5,
            codModelo: id,
            tipoAliado: '',
            ocupacionCliente: '',
            estudioCategoria: ''
          })
        }
      },
      closeModal: () => {
        modals.modalProduct.hide()
      },
      onDelete: async (product) => {
        const { isConfirmed } = await Swal.fire({
          title: '¿Esta seguro?',
          text: `¿Desea eliminar el producto con referencia ${product.CA120CODREFERENCIA}?`,
          showDenyButton: true,
          confirmButtonText: 'Sí'
        })
        if (isConfirmed) {
          new Swal({
            title: 'Espere por favor...',
            text: 'Eliminando registro...',
            allowOutsideClick: false
          })
          Swal.showLoading()
          const { response } = await deleteProduct(product.CA120CODPRODUCTO)
          if (response) {
            Swal.fire('Eliminado', '', 'success')
            return
          }
          Swal.fire('Ha ocurrido un error', '', 'error')
        }
      },
      checkPermission,
      canEdit: (product) => {
        // if (checkGroupUser('usuario-analista', userDataLoggedIn.value.perfil)) {
        //   return product.Ca100Estado === 'P'
        // }
        // if (checkGroupUser('usuario-comercial', userDataLoggedIn.value.perfil)) {
        //   return ['I', 'AP', 'AE', 'N', 'A', 'C'].includes(product.Ca100Estado)
        // }
        return true

      }

    }
  }

}
</script>

<style scoped>

.contenedor-producto {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between;
  align-items: flex-start; */
  margin-bottom: 20px;
  gap: 20px;
}

.producto {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  width: 170px;
  padding: 15px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.producto:hover {
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.producto-imagen {
  width: 100%;
  height: 150px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.producto-imagen img {
  width: 100%;
  height: 100%;
  /* max-width: 100%;
  max-height: 100%; */
  border-radius: 8px;
  object-fit: contain;
}

.producto-pie {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.producto-ref {
  font-size: 12px;
  font-weight: 500;
  color: #555;
  text-transform: uppercase;
}

.producto-botones {
  display: flex;
  gap: 10px;
}

.icono-editar {
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.icono-editar:hover {
  color: #0056b3;
}

.icono-eliminar {
  color: #dc3545;
  cursor: pointer;
  transition: color 0.3s ease;
}

.icono-eliminar:hover {
  color: #c82333;
}

.texto-ref {
  text-transform: uppercase;
}

</style>
