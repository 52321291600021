<script src="./AlliedOperation.js"/>
<template>
    <!-- Botonoes de navegación -->
    <div class="d-flex justify-content-evenly mb-2">
        <base-button-forms :classButton="tap===1?'btn-primary':'btn-outline-primary'" label="Información" type="button" icon="fa-solid fa-circle-info"
            :onClick="()=>tap=1"
            :permissionId="28"></base-button-forms>
        <base-button-forms v-if="typeOperation != 'newAllied'" :classButton="tap===2?'btn-primary':'btn-outline-primary'" label="Referencias" type="button"
            icon="fa-solid fa-users" :onClick="()=>tap=2"
            :disabled="isLoadingAllied" :permissionId="80"></base-button-forms>
        <base-button-forms v-if="typeOperation != 'newAllied'" :classButton="tap===3?'btn-primary':'btn-outline-primary'" label="Documentos" type="button"
            icon="fa-solid fa-file" :onClick="() =>  tap=3"
            :disabled="isLoadingAllied" :permissionId="33"></base-button-forms>
        <base-button-forms v-if="typeOperation != 'newAllied'" :classButton="tap===4?'btn-primary':'btn-outline-primary'" label="Estudio" type="button"
            icon="fa-solid fa-magnifying-glass"
            :onClick="() =>  tap=4" :disabled="isLoadingAllied"
            :permissionId="74"></base-button-forms>
    </div>
    <widget-alerts v-if="alert.showAlert &&  tap===1" :messages="alert.messageServer" :typeAlert="alert.typeAlert"></widget-alerts>
    <widget-loading v-if="isLoadingAllied" />
    <!-- Formulario Aliado -->
    <template v-else-if="tap===1">
        <form @submit.prevent="onSubmit" id="formAllied">
            <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                Datos de Identificación
            </div>
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100TipoPersona" idSelect="ca100TipoPersona" :errors="alert.errorsFields"
                        tipoLista="TPTERC" listaPadre="" listaPadreId="" title="Tipo Persona">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100TipoIdentidad" idSelect="ca100TipoIdentidad" :errors="alert.errorsFields"
                        tipoLista="TPIDEN" listaPadre="TPTERC" :listaPadreId="ca100TipoPersona" title="Tipo identificación" >
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100NumeroIdentidad" inputId="ca100NumeroIdentidad" :errors="alert.errorsFields"
                        title="Número identidad" typeInput="text" typeData="numId" ></base-input-forms>
                </div>
                <div v-if="ca100TipoPersona === '1'" class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="Ca100DigitoVerificacion" inputId="Ca100DigitoVerificacion" :errors="alert.errorsFields"
                        title="Dígito de chequeo" typeInput="text" typeData="digit"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100NombreComercial" inputId="ca100NombreComercial" :errors="alert.errorsFields"
                        title="Nombre comercial" typeInput="text"></base-input-forms>
                </div>
            </div>
            <!-- Campos para persona jurídica -->
            <template v-if="ca100TipoPersona === '1'">
                <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                    Datos Persona Jurídica
                </div>
                <div class="form-group row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100RazonSocial" inputId="ca100RazonSocial" :errors="alert.errorsFields"
                            title="Razón social" typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-list v-model="ca100TipoSociedad" idSelect="ca100TipoSociedad" :errors="alert.errorsFields"
                            tipoLista="TPSOCI" listaPadre="" listaPadreId="" title="Tipo de sociedad">
                        </base-select-list>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-list v-model="ca100TipoConstitucion" idSelect="ca100TipoConstitucion" :errors="alert.errorsFields"
                            tipoLista="TPCONS" listaPadre="" listaPadreId="" title="Tipo Constitución">
                        </base-select-list>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100NumeroConstitucion" inputId="ca100NumeroConstitucion"
                            :errors="alert.errorsFields" title="Número constitución" typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100NumeroNotaria" inputId="ca100NumeroNotaria" :errors="alert.errorsFields"
                            title="Número notaría" typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100CiudadNotaria" inputId="ca100CiudadNotaria" :errors="alert.errorsFields"
                            title="Ciudad notaría" typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100FechaConstitucion" inputId="ca100FechaConstitucion"
                            :errors="alert.errorsFields" title="Fecha constitución" typeInput="date"></base-input-forms>
                    </div>
                </div>
            </template>
            <!-- Campos para persona natural -->
            <template v-else>
                <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                    Datos Persona Natural
                </div>
                <div class="form-group row">
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100PrimerNombre" inputId="ca100PrimerNombre" :errors="alert.errorsFields"
                            title="Primer nombre" typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100SegundoNombre" inputId="ca100SegundoNombre" :errors="alert.errorsFields"
                            title="Segundo nombre" typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100PrimerApellido" inputId="ca100PrimerApellido" :errors="alert.errorsFields"
                            title="Primer apellido" typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100SegundoApellido" inputId="ca100SegundoApellido" :errors="alert.errorsFields"
                            title="Segundo apellido" typeInput="text"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100FechaNacimiento" inputId="ca100FechaNacimiento" :errors="alert.errorsFields"
                            title="Fecha nacimiento" typeInput="date"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-list v-model="ca100TipoGenero" idSelect="ca100TipoGenero" :errors="alert.errorsFields"
                            tipoLista="GENERO" listaPadre="" listaPadreId="" title="Sexo">
                        </base-select-list>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-select-list v-model="ca100TipoEstadoCivil" idSelect="ca100TipoEstadoCivil" :errors="alert.errorsFields"
                            tipoLista="ESTCIV" listaPadre="" listaPadreId="" title="Estado civil">
                        </base-select-list>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100FechaExpedicion" inputId="ca100FechaExpedicion" :errors="alert.errorsFields"
                            title="Fecha exp. Doc." typeInput="date"></base-input-forms>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <base-input-forms v-model="ca100CiudadExpedicion" inputId="ca100CiudadExpedicion" :errors="alert.errorsFields"
                            title="Ciudad Exp. doc" typeInput="text"></base-input-forms>
                    </div>
                </div>
            </template>
            <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                {{ ca100TipoPersona === '1' ? 'Datos de localización (Principal)' : 'Dirección Residencia' }}
            </div>
            <base-input-check-radio v-if="ca100TipoPersona === '2'" v-model="ca100DireccionEnvioCorrespondencia"
                typeInput="radio" title="Enviar correspondencia a la residencia"
                inputId="ca100DireccionEnvioCorrespondencia" inputValue="1" :errors="alert.errorsFields" />
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100CodigoPais1" idSelect="ca100CodigoPais1" :errors="alert.errorsFields"
                        tipoLista="PAIS" listaPadre="" listaPadreId="" title="País">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100CodigoDpto1" idSelect="ca100CodigoDpto1" :errors="alert.errorsFields"
                        tipoLista="DEPTO" listaPadre="PAIS" :listaPadreId="ca100CodigoPais1" title="Departamento">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100CodigoCiudad1" idSelect="ca100CodigoCiudad1" :errors="alert.errorsFields"
                        tipoLista="CIUDAD" listaPadre="DEPTO" :listaPadreId="ca100CodigoDpto1" title="Ciudad">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100Direccion1" inputId="ca100Direccion1" :errors="alert.errorsFields"
                        title="Dirección" typeInput="text"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100CorreoElectronico1" inputId="ca100CorreoElectronico1" :errors="alert.errorsFields"
                        title="E-mail" typeInput="email"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100TelefonoCelular1" inputId="ca100TelefonoCelular1" :errors="alert.errorsFields"
                        title="Celular" typeInput="text" typeData="phone"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100TelefonoFijo1" inputId="ca100TelefonoFijo1" :errors="alert.errorsFields"
                        title="Teléfono fijo" typeInput="text" typeData="phone"></base-input-forms>
                </div>
            </div>
            <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                {{ ca100TipoPersona === '1' ? ' Datos de localización (Notificaciones)' : 'Dirección Laboral' }}
            </div>
            <base-input-check-radio v-if="ca100TipoPersona === '2'" v-model="ca100DireccionEnvioCorrespondencia"
                typeInput="radio" title="Enviar correspondencia al trabajo" inputId="ca100DireccionEnvioCorrespondencia"
                inputValue="2" :errors="alert.errorsFields" />
            <base-input-check-radio v-if="ca100TipoPersona === '1'" v-model="checkCopyAddres" typeInput="checkbox"
                title="Es la misma dirección principal" :errors="alert.errorsFields" :onChange="copyAddress" />
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100CodigoPais2" idSelect="ca100CodigoPais2" :errors="alert.errorsFields"
                        tipoLista="PAIS" listaPadre="" listaPadreId="" title="País">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100CodigoDpto2" idSelect="ca100CodigoDpto2" :errors="alert.errorsFields"
                        tipoLista="DEPTO" listaPadre="PAIS" :listaPadreId="ca100CodigoPais2" title="Departamento">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100CodigoCiudad2" idSelect="ca100CodigoCiudad2" :errors="alert.errorsFields"
                        tipoLista="CIUDAD" listaPadre="DEPTO" :listaPadreId="ca100CodigoDpto2" title="Ciudad">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100Direccion2" inputId="ca100Direccion2" :errors="alert.errorsFields"
                        title="Dirección" typeInput="text"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100CorreoElectronico2" inputId="ca100CorreoElectronico2" :errors="alert.errorsFields"
                        title="E-mail" typeInput="email"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100TelefonoCelular2" inputId="ca100TelefonoCelular2" :errors="alert.errorsFields"
                        title="Celular" typeInput="text" typeData="phone"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="ca100TelefonoFijo2" inputId="ca100TelefonoFijo2" :errors="alert.errorsFields"
                        title="Teléfono fijo" typeInput="text" typeData="phone"></base-input-forms>
                </div>
            </div>
            <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                Datos tributarios
            </div>
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="CA100SectorEconomico" idSelect="CA100SectorEconomico" :errors="alert.errorsFields"
                        tipoLista="SECECO" listaPadre="" listaPadreId="" title="Sector económico">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="CA100ActividadComercial" idSelect="CA100ActividadComercial" :errors="alert.errorsFields"
                        tipoLista="CIIU" listaPadre="SECECO" :listaPadreId="CA100SectorEconomico" title="Código CIIU">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100ResponsableRenta" idSelect="ca100ResponsableRenta" :errors="alert.errorsFields"
                        tipoLista="RESREN" listaPadre="" listaPadreId="" title="Responsabilidad Renta">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="ca100ResponsableIVA" idSelect="ca100ResponsableIVA" :errors="alert.errorsFields"
                        tipoLista="RESIVA" listaPadre="" listaPadreId="" title="Responsable IVA">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="ca100RetenedorIVA" title="Retenedor de IVA" idSelect="ca100RetenedorIVA"
                        :options="[{ nombre: 'SI', codigo: 'SI' }, { nombre: 'NO', codigo: 'NO' }]" optionName="nombre"
                        optionId="codigo" :errors="alert.errorsFields"></base-select-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="ca100AutoRetenedorIva" title="Auto retenedor de IVA"
                        idSelect="ca100AutoRetenedorIva"
                        :options="[{ nombre: 'SI', codigo: 'SI' }, { nombre: 'NO', codigo: 'NO' }]" optionName="nombre"
                        optionId="codigo" :errors="alert.errorsFields"></base-select-forms>
                </div>
            </div>
            <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                Observaciones Ejecutivo Comercial
            </div>
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="Ca100CantEmpleados" inputId="Ca100CantEmpleados" :errors="alert.errorsFields"
                        title="Cantidad empleados" typeInput="text" typeData="digit"></base-input-forms>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <base-text-area v-model="Ca100ExperienciaActividadComercial" textareaId="Ca100ExperienciaActividadComercial"
                        title="Experiencia actividad" :errors="alert.errorsFields"></base-text-area>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <base-text-area v-model="Ca100ComentariosEjecutivoComercial" textareaId="Ca100ComentariosEjecutivoComercial"
                        title="Comentarios" :errors="alert.errorsFields"></base-text-area>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
                <base-button-forms classButton="btn-primary" label="Guardar" :isLoading="isLoadingOperationAllied"
                    :disabled="isLoadingOperationAllied"
                    :permissionId="typeOperation != 'newAllied' ? 28 : 27"></base-button-forms>
                <base-button-forms v-if="typeOperation != 'newAllied' && allied.Ca100Estado === 'I'"
                    classButton="btn-primary ms-2" label="Enviar a estudio" :disabled="isLoadingOperationAllied"
                    :onClick="onToStudy" type="button" :permissionId="28"></base-button-forms>
            </div>
        </form>
    </template>
    <!-- Referencias -->
    <reference-list v-else-if=" tap===2" :tipoModelo="1" :idAliado="allied.ca100CodAliado" idSolicitud="0"
        :tipoPersona="allied.ca100TipoPersona"></reference-list>
    <!-- Archivos -->
    <files-list v-else-if=" tap===3" tipoModelo="1" :codModelo="allied.ca100CodAliado" :tipoAliado="allied.ca100TipoPersona"
        ocupacionCliente="" estudioCategoria=""></files-list>
    <allied-study-stepper v-else-if=" tap===4" @on:closeModal="$emit('on:closeModal')" @on:LoadListAllied="$emit('on:LoadListAllied')"></allied-study-stepper>
</template>


