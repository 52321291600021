<template>
    <!-- Main Sidebar Container -->
    <aside class="main-sidebar sidebar-light-primary elevation-4">
        <!-- Brand Logo -->
        <a href="#" class="brand-link d-flex justify-content-start">
            <!-- img-circle elevation-3 -->
            <img src="/assets/img/logo/logo.png" alt="Logo" class="brand-image" style="opacity: .8">
            <span class="brand-text font-weight-light">BizzyPay</span>
        </a>
        <!-- Sidebar -->
        <div class="sidebar">
            <!-- Sidebar Menu -->
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="true">
                    <template v-if="routesStandar.length > 0">
                        <nav-link v-for="route in routesStandar" :key="route" :label="route.meta.title"
                            :page="route.path" :icon="'nav-icon ' + route.meta.icon" :children="route.children" />
                    </template>
                    <template v-if="routesAdmin.length > 0">
                        <li class="nav-header">
                            <b>OPCIONES ADMINISTRADOR</b>
                        </li>
                        <nav-link v-for="route in routesAdmin" :key="route" :label="route.meta.title" :page="route.path"
                            :icon="'nav-icon ' + route.meta.icon" :children="route.children" />
                    </template>
                    <template v-if="routesAcount.length > 0">
                        <li class="nav-header">
                            <b>OPCIONES DE LA CUENTA</b>
                        </li>
                        <nav-link v-for="route in routesAcount" :key="route" :label="route.meta.title"
                            :page="route.path" :icon="'nav-icon ' + route.meta.icon" :children="route.children" />
                    </template>
                    <!-- <nav-link label="Cambiar contraseña" page="change-password" icon="nav-icon fa-solid fa-key" /> -->
                </ul>
            </nav>
            <!-- /.sidebar-menu -->
        </div>
        <!-- /.sidebar -->

    </aside>
</template>

<script>
import useAuth from '@/modules/auth/composables/useAuth';
import router from '@/router';


import NavLink from './NavLink.vue';
import { ref } from 'vue';

export default {

    components: {
        NavLink
    },


    setup() {
        const routesAdmin = ref([])
        const routesAcount = ref([])
        const routesStandar = ref([])
        const {
            checkPermission,
            userDataLoggedIn

        } = useAuth()
        const routes = router.options.routes;
        routes.forEach(route => {

            if (route.meta.showNav == true && checkPermission(route.meta.permission)) {
                if (route.meta.group == 'standar') {
                    routesStandar.value.push(route);
                }
                if (route.meta.group == 'admin') {
                    routesAdmin.value.push(route);
                }
                if (route.meta.group == 'acount') {
                    routesAcount.value.push(route);
                }
                const childs = [];
                if (route.children) {
                    route.children.forEach(children => {
                        if (children.meta.showNav == true && checkPermission(children.meta.permission)) {
                            if (children.meta.group == 'acount') {
                                routesAcount.value.push(children);
                            } else {
                                childs.push(children)
                            }
                        }
                    })
                    route.children = childs
                }
            }
        });

        return {
            checkPermission,
            userDataLoggedIn,
            routesStandar,
            routesAdmin,
            routesAcount,
        }

    },
}
</script>
<style lang="css" scoped src="../css/adminlte.css"></style>
