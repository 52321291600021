import { computed } from "vue";
import { useStore } from "vuex";
import inputMaskFormat from '@/helpers/inputMaskFormat'


const useNotification = () => {
    const store = useStore()
    const { diffForHumans } = inputMaskFormat()

    const loadNotificationList = async (formData) => {
        return await store.dispatch('notifications/loadNotificationList', { formData })
    }

    const markAsRead = async (notificationId) => {
        return await store.dispatch('notifications/markAsRead', { notificationId })
    }
    const markAllAsRead = async () => {
        return await store.dispatch('notifications/markAllAsRead')
    }


    return {
        loadNotificationList,
        markAsRead,
        markAllAsRead,

        getDiffForHumans: (date) => {
            const { minuts, hours, days } = diffForHumans(date)

            if (minuts >= 0 && minuts < 60) {
                return `${minuts} min(s).`
            }
            if (minuts > 60 && minuts < 1440) {
                return `${hours} hora(s).`
            }
            return `${days} día(s).`

        },

        titleNotification: (notification) => {
            return notification.data['tipo'] + '-' + notification.data['cliente'] + '-' + notification.data['numero'] + '-' + notification.data['asunto']
        },
        notificationList: computed(() => store.state['notifications']['notificationList']),
        isLoading: computed(() => store.state['notifications']['isLoading']),
        alert: computed(() => store.state['notifications']['alert'])

    }
}
export default useNotification