import store from '@/store'
import useAuth from '@/modules/auth/composables/useAuth'
import Swal from 'sweetalert2'


/**
 * Validar si el usuario se encuentra autenticado
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 * @returns void
 */
export const isAuthenticatedGuard = async (to, from, next) => {
    if (localStorage.getItem('authStatus')=='authenticated') {
        const { response } = await store.dispatch('auth/checkAuthentication')
        if (response) {
            next()
            return
        }
    }
    next({ name: 'login' })
    localStorage.removeItem('authStatus')
    return

}
/**
 * Validar si el usuario no esta autenticado
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 * @returns void
 */
export const isNotAuthenticatedGuard = async (to, from, next) => {

    if (store.state['auth']['status'] != 'authenticated') {
        next()
        return
    }
    next({ name: 'start' })
    return


}

/**
 * Validar si el usuario tiene autorización sobre una ruta
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 * @returns void
 */
export const isAuthorized = async (to, from, next) => {
    const { checkPermission } = useAuth()
    if (checkPermission(to.meta.permission)) {
        next()
        return
    }
    next({ name: 'error-403' })
    return

}
/**
 * Validar si el usuario analista tiene turno en estado "AA"
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 * @returns void
 */
export const isActivateShift = async (to, from, next) => {
    const { userDataLoggedIn } = useAuth()
    if (userDataLoggedIn.value.Turno === 'AA') {
        next()
        return
    }
    Swal.fire('Debe activar el turno para continuar', '', 'error')
    next({ name: 'error-403' })
    return

}
/**
 * Validar si el usuario almacén tiene aliado y almacén asignado
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 * @returns 
 */
export const hasActiveShopAllied = async (to, from, next) => {
    const { userDataLoggedIn, checkGroupUser } = useAuth()
    if (!checkGroupUser('usuario-almacen', userDataLoggedIn.value.perfil)) {
        next()
        return
    }
    if (userDataLoggedIn.value.codigo_aliado > 0 && userDataLoggedIn.value.codigo_almacen_activo > 0) {
        next()
        return
    }
    next({ name: 'select-user-shop' })
    return
}
/**
 * Validar si el usuario almacén no tiene asignado aliado y almacén
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 * @returns 
 */

export const hasNoActiveShopAllied = async (to, from, next) => {
    const { userDataLoggedIn, checkGroupUser } = useAuth()
    if (!checkGroupUser('usuario-almacen', userDataLoggedIn.value.perfil)) {
        next()
        return
    }
    if (userDataLoggedIn.value.codigo_aliado === 0 || userDataLoggedIn.value.codigo_almacen_activo === 0) {
        next()
        return
    }
    next({ name: 'start' })
    return

}

/**
 * Validar si el usuario ha cambiado la contraseña por primer login
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 * @returns 
 */
export const hasChangedPassword = async (to, from, next) => {
    const { userDataLoggedIn } = useAuth()
    if (userDataLoggedIn.value.changed_password === null) {
        next({ name: 'change-password' })
        return
    }
    next()
    return


}
