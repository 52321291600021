import { isAuthenticatedGuard, isAuthorized,hasChangedPassword } from '@/guard/auth-guard'
import ReportsView from '../views/ReportsView.vue'
export default{
    name:'reports',
    component:()=>import(/* webpackChunkName: "Auth Layaout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children:[
        {
            path:'',
            beforeEnter: [isAuthenticatedGuard,hasChangedPassword,isAuthorized],
            name:'reports-index',
            meta:{permission:124,showNav: false},
            component:ReportsView
        },
    ]
    
}