export default () => ({
    dataReports:[],
    requestGraphics: {},
    shivamAccountGraphics: {},
    salesGraphics: {},
    paymentsGraphics: {},
    creditGraphics: {},
    isLoading: {
        reports:false,
        requestGraphicsExport: false,
        salesGraphicsExport: false,
        paymentsGraphicsExport: false,
        creditGraphicsExport: false,
        shivamGraphicsExport: false,
        requestGraphics: false,
        shivamGraphics: false,
        salesGraphics: false,
        paymentsGraphics: false,
        creditGraphics: false,
        reportsParameters:false,
    },

    alert: {
        messageServer: null,
        errorsFields: {},
        showAlert: false,
        typeAlert: '',
    },
    reportsParameters: {}
})