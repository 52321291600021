import { computed } from "vue";
import { useStore } from "vuex";

const useReports = () => {
    const store = useStore()

    const getListConfig = async() => {
        return await  store.dispatch('reports/getListConfig')
    }
    const getDataReports = async(formData,typeGraphics) => {
        return await  store.dispatch('reports/getDataReports', { formData,typeGraphics })
    }
    const exportToExcel = async(formData,typeGraphics) => {
        return await  store.dispatch('reports/exportToExcel',{formData,typeGraphics})
    }
   

    return {
        getDataReports,
        exportToExcel,
        getListConfig,
        getDataReports,



       
       
        dataReports: computed(() => store.state['reports']['dataReports']),
        alert: computed(() => store.state['reports']['alert']),
        requestGraphics: computed(() => store.state['reports']['requestGraphics']),
        reportsParameters: computed(() => store.state['reports']['reportsParameters']),
        salesGraphics: computed(() => store.state['reports']['salesGraphics']),
        paymentsGraphics: computed(() => store.state['reports']['paymentsGraphics']),
        creditGraphics: computed(() => store.state['reports']['creditGraphics']),
        shivamAccountGraphics: computed(() => store.state['reports']['shivamAccountGraphics']),
        isLoading: computed(() => store.state['reports']['isLoading']),

    }



}


export default useReports