import { computed, ref } from 'vue'
import { useStore } from 'vuex'
const useFiles = () => {

    const store = useStore()

    const formData = ref({
        tipoModelo: '',
        codModelo: '',
        tipoAliado: '',
        ocupacionCliente: '',
        estudioCategoria: '',
    })

    const loadFilesList = async (formData) => {
        const resp = await store.dispatch('files/loadFilesList', formData)
        return resp
    }

    const uploadFile = async (formData, fromCamera = false) => {
        const resp = await store.dispatch('files/uploadFile', { formData, fromCamera })
        return resp
    }
    const deleteFile = async (formData) => {
        const resp = await store.dispatch('files/deleteFile', { formData })
        return resp
    }
    const checkFileExist = async (url) => {
        const resp = await store.dispatch('files/checkFileExist', url)
        return resp
    }

    const validateFile = async (formData) => {
        const resp = await store.dispatch('files/validateFile', formData)
        return resp
    }










    return {
        formData,

        loadFilesList,
        uploadFile,
        checkFileExist,
        validateFile,
        deleteFile,

        filesList: computed(() => store.state['files']['filesList']),
        errorsFields: computed(() => store.state['files']['errorsFields']),
        isLoading: computed(() => store.state['files']['isLoading']),
        isLoadingOperationFile: computed(() => store.state['files']['isLoadingOperationFile']),
        isCheckingFile: computed(() => store.state['files']['isCheckingFile']),
        showAlert: computed(() => store.state['files']['showAlert']),
        typeAlert: computed(() => store.state['files']['typeAlert']),
        messageServer: computed(() => store.state['files']['messageServer']),
        filesParameters:computed(()=>store.state['files']['filesParameters'])

    }
}

export default useFiles
