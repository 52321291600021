<template>
    <!-- Botones navegación -->
    <div class="d-flex justify-content-evenly mt-2  mb-2 bg-light rounded">
        <base-button-forms :classButton="tap === 1 ? 'btn-primary' : 'btn-outline-primary'" label="Información" type="button"
            icon="fa-solid fa-circle-info" :onClick="() => tap = 1" :permissionId="64"></base-button-forms>
        <base-button-forms v-if="typeOperation != 'newProduct'" :classButton="tap === 2 ? 'btn-primary' : 'btn-outline-primary'"
            label="Imágenes" type="button" icon="fa-solid fa-file" :onClick="() => tap = 2" :disabled="isLoadingProduct"
            :permissionId="123"></base-button-forms>
    </div>
    <widget-alerts v-if="showAlert && tap === 1" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
    <widget-loading v-if="isLoadingProduct" />
    <!-- Formulario Producto -->
    <template v-else-if="tap === 1 || typeOperation === 'newProduct'">
        <form @submit.prevent="onSubmit" id="formProduct">
            <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
                Datos de Básicos
            </div>
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="CA120CODPRODUCTOSECCION" title="Categoria Productos"
                        :options="productCategorie" optionId="ca001CodigoItemDeLista"
                        optionName="ca001DescripcionItemDeLista" :errors="errorsFields"
                        idSelect="CA120CODPRODUCTOSECCION"></base-select-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="CA120CODREFERENCIA" inputId="CA120CODREFERENCIA" :errors="errorsFields"
                        title="Código Referencia" typeInput="text"></base-input-forms>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="CA120ESTADO" title="Estado"
                        :options="[{ nombre: 'Activo', codigo: 'A' }, { nombre: 'Inactivo', codigo: 'I' }]"
                        optionId="codigo" optionName="nombre" :errors="errorsFields"
                        idSelect="CA120ESTADO"></base-select-forms>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <base-text-area v-model="CA120DESC" textareaId="CA120DESC" title="Descripción"
                        :errors="errorsFields"></base-text-area>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
                <base-button-forms classButton="btn-primary" label="Guardar" :isLoading="isLoadingOperationProduct"
                    :disabled="isLoadingOperationProduct"
                    :permissionId="typeOperation != 'newProduct' ? 66 : 65"></base-button-forms>
            </div>
        </form>
        <!-- Asociar producto almacén -->
        <div class="badge bg-primary text-wrap d-flex justify-content-start mt-4">
            Asociar producto a almacén
        </div>
        <form @submit.prevent="onStoreProductShop">
            <div class="form-group row">
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="formDataProductoAlmacen.almacen_producto" title="Almacenes"
                        idSelect="almacen_producto" :options="shopAuthorizedLists" optionName="nombreAlmacen"
                        optionId="codAlmacen" :errors="errorsFields"></base-select-forms>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formDataProductoAlmacen.precio" inputId="precio" :errors="errorsFields"
                        title="Precio" typeInput="text" typeData="currence" placeholder="0"
                        inputClass="text-end"></base-input-forms>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formDataProductoAlmacen.precio_descuento" inputId="precio_descuento"
                        :errors="errorsFields" title="Precio Desc." typeInput="text" typeData="currence" placeholder="0"
                        inputClass="text-end"></base-input-forms>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formDataProductoAlmacen.cashback" inputId="cashback"
                        :errors="errorsFields" title="% Cashback." typeInput="number" typeData="digit" placeholder="%"
                        inputClass="text-end"></base-input-forms>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-text-area v-model="formDataProductoAlmacen.colores" textareaId="colores" title="Colores"
                        :errors="errorsFields"></base-text-area>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-text-area v-model="formDataProductoAlmacen.tallas" textareaId="tallas" title="Tallas"
                        :errors="errorsFields"></base-text-area>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formDataProductoAlmacen.fecha_ini_promocion"
                        inputId="fecha_ini_promocion" :errors="errorsFields" title="Inicio promoción"
                        typeInput="date"></base-input-forms>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formDataProductoAlmacen.fecha_fin_promocion"
                        inputId="fecha_fin_promocion" :errors="errorsFields" title="Fin Promoción"
                        typeInput="date"></base-input-forms>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12 mb-2" v-if="typeOperation != 'newProduct'">
                    <base-button-forms classButton="btn-primary mt-3" label="Guardar"
                        :isLoading="isLoadinStoreProductShop" :disabled="isLoadinStoreProductShop"
                        :permissionId="66"></base-button-forms>
                </div>
                <base-table-forms v-if="typeOperation != 'newProduct'" :whitPagination="false"
                    :headers="['Almacén', 'Precio', 'Precio Descuento', '%Cashback', 'Inicio Promoción', 'Fin Promoción', 'Acciones']">
                    <tr v-for="almacen in product.almacenes" :key="almacen.codAlmacen">
                        <td class="text-center">{{ almacen.nombreAlmacen }}</td>
                        <td class="text-center">{{ currenceFormat(almacen.pivot.CA121VLRPRECIOORIGINAL) }}
                        </td>
                        <td class="text-center">{{ currenceFormat(almacen.pivot.CA121VLRPRECIODESCUENTO) }}
                        </td>
                        <td class="text-center">{{ almacen.pivot.CA121X100CASHBACK }}</td>
                        <td class="text-center">{{ inputDate(almacen.pivot.CA121FECHAINICIOPROMOCION, 3) }}</td>
                        <td class="text-center">{{ inputDate(almacen.pivot.CA121FECHAFINPROMOCION, 3) }}</td>
                        <td>
                            <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
                                { label: 'Editar', action: () => { onEditProductoAlmacen(almacen) }, permissionId: 66, visible: true },
                                { label: 'Eliminar', action: () => { onDeleteProductShop(almacen) }, permissionId: 67, visible: true },
                            ]"></widget-dropdown>
                        </td>
                    </tr>
                </base-table-forms>
            </div>
        </form>
    </template>
    <!-- Imágenes producto -->
    <template v-else-if="tap === 2">
        <widget-loading v-if="isLoadingFiles" />
        <widget-alerts v-if="showAlertFile" :messages="messageServerFile" :typeAlert="typeAlertFile"></widget-alerts>
        <div class="form-group row justify-content-center">
            <div v-for="file in filesList.tiposArchivos" :key="file.codArchivo" class="col-lg-3 col-md-4 col-sm-6 mt-3">
                <div class="contenedor-imagen">
                    <div class="contenedor-acciones">
                        <i v-if="isImageLoaded[file.codArchivo] && checkPermission(126) && checkPermission(66) && !isOperationImageLoading[file.codArchivo]"
                            class="fa-solid fa-circle-xmark text-primary accion"
                            @click="onDeleteFile(file.codArchivo)"></i>
                    </div>
                    <div class="contenedor-cuerpo">
                        <widget-spinner v-if="isOperationImageLoading[file.codArchivo]" :typeSpinner="1"
                            classSpinner="center text-light"></widget-spinner>
                        <i v-if="isOperationImageError[file.codArchivo]" class="fa-solid fa-circle-xmark  center text-danger"
                            style="font-size: xx-large;"></i>
                        <img v-if="!localImage[file.codArchivo]" :src="imageSrc(file)" alt=""
                            :data-cod-archivo="file.codArchivo" class="img-rounded imagen-texto"
                            :class="{ 'oscurecer-imagen': isOperationImageLoading[file.codArchivo] }"
                            @load="onImageLoad(file.codArchivo)" @error="handleImageError(file.codArchivo)"
                            @click="checkPermission(66) && !isOperationImageLoading[file.codArchivo] ? onSelectImage(file.codArchivo) : null">
                        <img v-else :src="localImage[file.codArchivo]" alt="" class="img-rounded imagen-texto"
                            :class="{ 'oscurecer-imagen': isOperationImageLoading[file.codArchivo] }"
                            @click="checkPermission(66) && !isOperationImageLoading[file.codArchivo] ? onSelectImage(file.codArchivo) : null">
                        <base-input-file v-show="false" :inputId="'file' + file.codArchivo" :onChange="onUploadFile"
                            :paramFunction="file.codArchivo" :permissionId="34" />
                    </div>
                </div>
            </div>
        </div>

    </template>
</template>

<script>
import { computed, ref, defineAsyncComponent, toRef, watch } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2';

import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectListLocation from '@/common/forms/BaseSelectListLocation.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputCheckRadio from '@/common/forms/BaseInputCheckRadio.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import BaseInputFile from '@/common/forms/BaseInputFile.vue';
import WidgetSpinner from '@/common/widgets/WidgetSpinner.vue';

import useProduct from '@/modules/product/composables/useProduct'
import useShop from '@/modules/shop/composables/useShop'
import useAuth from '@/modules/auth/composables/useAuth'
import useFiles from '@/modules/files/composables/useFiles'
import inputFormat from '@/helpers/inputMaskFormat'
import { Toast } from "@/helpers/toastSwalert"


export default {

    props: {
        typeOperation: {
            default: ''
        }

    },

    components: {
        BaseSelectList,
        BaseSelectForms,
        BaseInputForms,
        WidgetLoading,
        BaseSelectListLocation,
        BaseTextArea,
        BaseButtonForms,
        BaseInputCheckRadio,
        WidgetAlerts,
        BaseTableForms,
        WidgetDropdown,
        BaseInputFile,
        WidgetSpinner


    },
    emits: ['on:createdProduct', 'on:closeModal'],
    setup(props, { emit }) {
        const store = useStore()
        const tap = ref(1)
        const localImage = ref({})
        const isImageLoaded = ref({})
        const isOperationImageLoading = ref({})
        const isOperationImageError = ref({})
        const formDataProductoAlmacen = ref({
            almacen_producto: '',
            precio: '',
            precio_descuento: '',
            cashback: '',
            colores: '',
            tallas: '',
            fecha_ini_promocion: '',
            fecha_fin_promocion: '',
        })
        const {
            product,
            productParameters,
            productCategorie,
            createProduct,
            updateProduct,
            errorsFields,
            isLoading,
            isLoadingProduct,
            isLoadingOperationProduct,
            isLoadinStoreProductShop,
            showAlert, messageServer, typeAlert,
            storeProductShop,
            deleteProductShop,
        } = useProduct()
        const { shopAuthorizedLists } = useShop()
        const { checkPermission } = useAuth();
        const { inputDate, inputCurrence, inputNumId, inputPhone } = inputFormat()
        const { isLoading: isLoadingFiles,
            loadFilesList,
            filesList,
            uploadFile,
            deleteFile,
            showAlert: showAlertFile,
            typeAlert: typeAlertFile,
            messageServer: messageServerFile
        } = useFiles()

        const currenceFormat = (currence) => {
            return inputCurrence(currence, 0, 'state');
        }
        const updateThumbnail = (url) => {
            const idx = store.state.product.productList.data.map(p => p.CA120CODPRODUCTO).indexOf(product.value.CA120CODPRODUCTO)
            store.state.product.productList.data[idx].thumbnail = url + '?' + Date.now()
        }
        const preview = (file, codArchivo) => {
            const fr = new FileReader()
            fr.onload = () => localImage.value[codArchivo] = fr.result
            fr.readAsDataURL(file)
        }
        const limpiarFormDataProductoAlmacen = () => {
            for (const key in formDataProductoAlmacen.value) {
                formDataProductoAlmacen.value[key] = ''
            }
        }


        return {
            errorsFields,
            isLoadingProduct,
            product,
            tap,
            isLoadingOperationProduct,
            showAlert, messageServer, typeAlert,
            productCategorie,
            shopAuthorizedLists,
            formDataProductoAlmacen,
            storeProductShop,
            isLoadinStoreProductShop,
            inputDate,
            currenceFormat,
            filesList,
            isLoadingFiles,
            showAlertFile,
            typeAlertFile,
            messageServerFile,
            localImage,
            checkPermission,
            isImageLoaded,
            isOperationImageLoading,
            isOperationImageError,

            limpiarFormDataProductoAlmacen,


            onSubmit: async (e) => {
                const formData = new FormData(e.target)
                // Crear producto
                if (props.typeOperation === 'newProduct') {
                    // Agregar los datos de producto almacén
                    for (const key in formDataProductoAlmacen.value) {
                        formData.append(key, formDataProductoAlmacen.value[key])
                    }
                    const { response } = await createProduct(formData)
                    if (response) {
                        emit('on:createdProduct', product.value.CA120CODPRODUCTO)
                        // Limpiar formDataProductoAlmacen
                        limpiarFormDataProductoAlmacen()

                    }
                } else {
                    // Actualizar producto
                    const { response } = await updateProduct(product.value.CA120CODPRODUCTO, formData)

                }

            },

            onStoreProductShop: async (e) => {
                const formData = new FormData(e.target)
                const { response } = await storeProductShop(product.value.CA120CODPRODUCTO, formData)
                if (response) {
                    // Limpiar formDataProductoAlmacen
                    limpiarFormDataProductoAlmacen()
                }
            },
            onSelectImage: (codArchivo) => {
                document.getElementById('file' + codArchivo).click()

            },
            onUploadFile: async (codArchivo, event) => {
                isOperationImageLoading.value[codArchivo] = true
                isOperationImageError.value[codArchivo] = false
                const file = event.target.files[0]
                if (!file) {
                    localImage.value[codArchivo] = null
                    isOperationImageLoading.value[codArchivo] = false
                    return
                }
                // Vista previa de la imagen
                preview(file, codArchivo)

                // new Swal({
                //     title: 'Subiendo archivo...',
                //     allowOutsideClick: false
                // })
                // Swal.showLoading()
                // Subir archivo
                const form = new FormData();
                form.append('file', file)
                form.append('tipoModelo', 5)
                form.append('codModelo', product.value.CA120CODPRODUCTO)
                form.append('tipoArchivo', codArchivo)
                const { response, data } = await uploadFile(form)
                if (response) {
                    // Validar si se cambio la primera imagen, para actualizar el thumbnail
                    if (codArchivo == 16) {
                        updateThumbnail(data.URL)
                    }
                    // Swal.fire('Archivo subido', '', 'success')
                    isImageLoaded.value[codArchivo] = true
                    localImage.value[codArchivo] = ''
                    isOperationImageLoading.value[codArchivo] = false
                    return
                } else {
                    isOperationImageError.value[codArchivo] = true
                }
                isOperationImageLoading.value[codArchivo] = false
                // Swal.fire('Ha ocurrido un error', '', 'error')
            },
            onDeleteFile: async (codArchivo) => {
                isOperationImageLoading.value[codArchivo] = true
                const { isConfirmed } = await Swal.fire({
                    title: '¿Esta seguro?',
                    text: `¿Desea eliminar esta imagen?`,
                    showDenyButton: true,
                    confirmButtonText: 'Sí'
                })
                if (isConfirmed) {
                    // new Swal({
                    //     title: 'Espere por favor...',
                    //     text: 'Eliminando imagen...',
                    //     allowOutsideClick: false
                    // })
                    // Swal.showLoading()
                    const form = new FormData();
                    form.append('tipoModelo', 5)
                    form.append('codModelo', product.value.CA120CODPRODUCTO)
                    form.append('tipoArchivo', codArchivo)
                    const { response } = await deleteFile(form)
                    if (response) {

                        // Swal.fire('OK', 'Imagen eliminada', 'success')
                        if (!isLoadingFiles.value) {
                            await loadFilesList({
                                tipoModelo: 5,
                                codModelo: product.value.CA120CODPRODUCTO,
                                tipoAliado: '',
                                ocupacionCliente: '',
                                estudioCategoria: ''
                            })
                        }
                        // Validar si se cambio la primera imagen, para actualizar el thumbnail
                        if (codArchivo == 16) {
                            updateThumbnail('/assets/img/notFoundImage.png')
                        }

                        Swal.close()
                    }

                }
                isOperationImageLoading.value[codArchivo] = false
            },

            onEditProductoAlmacen: (almacen) => {
                formDataProductoAlmacen.value.almacen_producto = almacen.codAlmacen
                formDataProductoAlmacen.value.precio = currenceFormat(almacen.pivot.CA121VLRPRECIOORIGINAL)
                formDataProductoAlmacen.value.precio_descuento = currenceFormat(almacen.pivot.CA121VLRPRECIODESCUENTO)
                formDataProductoAlmacen.value.cashback = almacen.pivot.CA121X100CASHBACK
                formDataProductoAlmacen.value.colores = almacen.pivot.CA121COLORES
                formDataProductoAlmacen.value.tallas = almacen.pivot.CA121TALLAS
                formDataProductoAlmacen.value.fecha_ini_promocion = inputDate(almacen.pivot.CA121FECHAINICIOPROMOCION, 3)
                formDataProductoAlmacen.value.fecha_fin_promocion = inputDate(almacen.pivot.CA121FECHAFINPROMOCION, 3)

            },
            onDeleteProductShop: async (almacen) => {
                const shopCount = product.value.almacenes.length
                const { isConfirmed } = await Swal.fire({
                    title: '¿Esta seguro?',
                    text: `¿Desea eliminar asociación del producto con el almacén ${almacen.nombreAlmacen}? ${shopCount === 1 ? "Perderá acceso total al producto." : ""}`,
                    showDenyButton: true,
                    confirmButtonText: 'Sí'
                })
                if (isConfirmed) {
                    new Swal({
                        title: 'Espere por favor...',
                        text: 'Eliminando registro...',
                        allowOutsideClick: false
                    })
                    Swal.showLoading()
                    const { response } = await deleteProductShop(almacen.pivot.CA121CODPRODUCTO, almacen.codAlmacen)
                    // Quitar del listado el producto si era el último almacén que tenía asociado para el usuario
                    if (shopCount === 1) {
                        emit('on:closeModal')
                        store.commit('product/deleteProduct', { productId: almacen.pivot.CA121CODPRODUCTO })
                    }
                }
            },
            onImageLoad: (codArchivo) => {
                // Cuando la imagen se carga exitosamente
                isImageLoaded.value[codArchivo] = true
            },
            handleImageError: (codArchivo) => {
                // Cuando ocurre un error al cargar la imagen
                if (isImageLoaded.value[codArchivo] !== false) {
                    isImageLoaded.value[codArchivo] = false;
                }

            },
            imageSrc: (file) => {
                return file.rutaImagen + '?' + Date.now();
                return isImageLoaded.value[file.codArchivo] === false
                    ? '/assets/img/notFoundImage.png'
                    : file.rutaImagen + '?' + Date.now();
            },
            CA120CODREFERENCIA: computed({
                get: () => store.state['product']['product']['CA120CODREFERENCIA'],
                set: (val) => store.commit('product/updateField', { field: 'CA120CODREFERENCIA', value: val })
            }),
            CA120CODPRODUCTOSECCION: computed({
                get: () => store.state['product']['product']['CA120CODPRODUCTOSECCION'],
                set: (val) => store.commit('product/updateField', { field: 'CA120CODPRODUCTOSECCION', value: val })
            }),
            CA120DESC: computed({
                get: () => store.state['product']['product']['CA120DESC'],
                set: (val) => store.commit('product/updateField', { field: 'CA120DESC', value: val })
            }),
            CA120ESTADO: computed({
                get: () => store.state['product']['product']['CA120ESTADO'],
                set: (val) => store.commit('product/updateField', { field: 'CA120ESTADO', value: val })
            }),

        }

    },
}
</script>
<style scoped>
.contenedor-imagen {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    background-color: #f8f9fa;
}

.contenedor-imagen:hover {
    transform: scale(1.05);
}

.contenedor-acciones {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.8);
    padding: 5px;
    border-radius: 50%;
}

.contenedor-cuerpo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    background-color: #f1f3f5;
}

img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 4px;
    cursor: pointer;
}

.oscurecer-imagen {
    filter: brightness(50%);
}

.accion {
    font-size: 18px;
    cursor: pointer;
}

.imagen-texto::after {
    position: absolute;
    content: 'Seleccionar imagen';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    color: black;
    font-size: 16px;
    text-align: center;

}

.center {
    position: absolute;
    left: 50%;
    right: 50%;
    /* transform: translate(-50%, 0%); */
    z-index: 6;
}
</style>
