<template>
    <widget-loading v-if="isLoading" />
    <template v-else>
        <!-- Formulario para crear/actulizar referencias -->
        <form @submit.prevent="onSubmit" v-if="checkPermission(81)">
            <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
            <div class="form-group row">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-select-list v-model="formData.tipoReferencia" idSelect="tipoReferencia" :errors="errorsFields"
                        tipoLista="TPREF" listaPadre="TPTERC" :listaPadreId="`${tipoPersona}`" title="Tipo Referencia">
                    </base-select-list>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formData.nombreReferencia" title="Nombre" :errors="errorsFields"
                        inputId="nombreReferencia"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formData.apellidoReferencia" title="Apellido" :errors="errorsFields"
                        inputId="apellidoReferencia"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formData.celularReferencia" title="Celular" :errors="errorsFields"
                        inputId="celularReferencia" typeData="phone"></base-input-forms>
                </div>
            </div>
            <div class="form-group row mb-3">
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-input-forms v-model="formData.fijoReferencia" title="Teléfono fijo" :errors="errorsFields"
                        inputId="fijoReferencia" typeData="phone"></base-input-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="tipoPersona === '2'">
                    <base-select-forms v-model="formData.parentesco" title="Parentesco" idSelect="parentesco"
                        :options="relationshipSelect" optionName="nombreParentesco" optionId="codParentesco"
                        :errors="errorsFields"></base-select-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <base-button-forms classButton="btn-primary mt-3" :isLoading="isLoadinOperationReference"
                        :disabled="isLoadinOperationReference" :label="operation != 'edit' ? 'Agregar' : 'Actualizar'"
                        :permissionId="81"></base-button-forms>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12" v-if="operation === 'edit'">
                    <base-button-forms classButton="btn-secondary mt-3" :isLoading="isLoadinOperationReference"
                        :disabled="isLoadinOperationReference" label="Cancelar edición" type="button"
                        :onClick="onCancelEdit" :permissionId="81"></base-button-forms>
                </div>
            </div>
        </form>
        <!-- Tabla para mostrar listado de referencias -->
        <widget-select-paginator v-model="formData.numPages"
            :onChange="callLoadReferences"></widget-select-paginator>
        <base-table-forms :data="referenceList" :dataFunction="callLoadReferences" :headers="headers"
            :orderColumn="formData.column" :order="formData.order" :whitOrder="true">
            <tr v-for="reference in referenceList.data" :key="reference.CA115CODREFERENCIA">
                <td class="text-center">{{ reference.nombreTipoModelo }}</td>
                <td class="text-center">{{ reference.nombreTipoReferencia }}</td>
                <td>{{
        `${reference.CA115NOMBRE} ${reference.CA115APELLIDO}` }}
                </td>
                <td class="text-end">{{ reference.CA115CELULAR }}</td>
                <td class="text-end">{{ reference.CA115TELEFONOFIJO }}</td>
                <td v-if="tipoPersona==2" class="text-center">{{ reference.nombreParentesco }}</td>
                <td>
                    <widget-dropdown icon="fa-solid fa-pen-to-square text-primary" :dropdownItem="[
        { label: 'Editar', action: () => { onEdit(reference) }, permissionId: 82, visible: true },
        { label: 'Referenciar', action: () => { openModal(reference) }, permissionId: 83, visible: tipoModelo === 1 },
        { label: 'Eliminar', action: () => { onDelete(reference) }, permissionId: 84, visible: true },
    ]">
                    </widget-dropdown>
                </td>
            </tr>
        </base-table-forms>
    </template>
    <!-- Modal Referenciar -->
    <widget-modal-boostrap modalId="modalReference" :modalTitle="modalTittle"
        modalClassSize="modal-dialog-scrollable modal-lg" :showFooter="false" modalStatic="static"
        :modalClass="'border-primary mb-3'">
        <template v-slot:modalBody>
            <form @submit.prevent="onStoreAnswerReference">
                <widget-alerts v-if="showAlert" :messages="messageServer" :typeAlert="typeAlert"></widget-alerts>
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
                    <base-select-forms v-model="tipoRespuesta" title="Decisión Tomada" idSelect="tipoRespuesta"
                        :options="[{ nombre: 'Negativo', codigo: 'N' }, { nombre: 'Positivo', codigo: 'P' }]"
                        optionName="nombre" optionId="codigo" :errors="errorsFields"></base-select-forms>
                </div>
                <div class="col-lg-10 col-md-4 col-sm-6 col-xs-12">
                    <base-text-area v-model="observacionesRespuesta" textareaId="observacionesRespuesta"
                        title="Comentarios" :errors="errorsFields"></base-text-area>
                </div>
                <base-button-forms classButton="btn-primary mt-3" :isLoading="isLoadinOperationReference"
                    :disabled="isLoadinOperationReference" label="Guardar" :permissionId="81"></base-button-forms>
            </form>
        </template>
    </widget-modal-boostrap>
</template>

<script>
import { ref, onMounted } from 'vue';
import Swal from 'sweetalert2'
import { Modal } from 'bootstrap';

import useReferences from '@/modules/references/composables/useReferences'
import useAuth from '@/modules/auth/composables/useAuth'
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue'

import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import BaseTextArea from '@/common/forms/BaseTextArea.vue';


export default {

    props: {
        tipoModelo: {
            require: true
        },
        idAliado: {
            require: true
        },
        idSolicitud: {
            require: true
        },

        tipoPersona: {
            require: true
        }

    },
    components: {
        WidgetSelectPaginator,
        BaseTableForms,
        WidgetDropdown,
        WidgetLoading,
        BaseSelectList,
        BaseInputForms,
        BaseSelectForms,
        BaseButtonForms,
        WidgetAlerts,
        WidgetModalBoostrap,
        BaseTextArea

    },

    setup(props) {
        const {
            loadReferenceList,
            referenceList,
            isLoading,
            isLoadinOperationReference,
            errorsFields,
            relationshipSelect,
            createReference,
            updateReference,
            deleteReference,
            showAlert,
            messageServer,
            typeAlert,
            storeAnswerReference
        } = useReferences()
        const { checkPermission } = useAuth()

        const formData = ref({
            tipoModelo: props.tipoModelo,
            idAliado: props.idAliado,
            idSolicitud: props.idSolicitud,
            tipoPersona: props.tipoPersona,
            tipoReferencia: '',
            nombreReferencia: '',
            apellidoReferencia: '',
            celularReferencia: '',
            fijoReferencia: '',
            parentesco: '',
            numPages: '5',
            page: 1,
            column: 'CA115NOMBRE',
            order: 'asc'

        })

        const headers = ref([
            { encabezado: 'Tipo modelo', columna: 'nombreTipoModelo' },
            { encabezado: 'Tipo referencia', columna: 'nombreTipoReferencia' },
            { encabezado: 'Nombre', columna: 'CA115NOMBRE' },
            { encabezado: 'Celular', columna: 'CA115CELULAR' },
            { encabezado: 'Teléfono', columna: 'CA115TELEFONOFIJO' },
            { encabezado: 'Parentesco', columna: 'nombreParentesco' },
            { encabezado: 'Acciones', columna: null },

        ])
console.log(props.tipoPersona);
        if(props.tipoPersona!=2){
            headers.value=headers.value.filter((h)=>h.encabezado!='Parentesco')
        }
        const tipoRespuesta = ref()
        const observacionesRespuesta = ref()
        const modalTittle = ref('Referenciar a ')
        const modals = ref({ modalReference: null })
        const clearFormData = () => {
            formData.value.tipoReferencia = ''
            formData.value.nombreReferencia = ''
            formData.value.apellidoReferencia = ''
            formData.value.celularReferencia = ''
            formData.value.fijoReferencia = ''
            formData.value.parentesco = ''
        }
        const codReferencia = ref();

        const operation = ref('newReference')

        const callLoadReferences = (page, column) => {
            formData.value.page = page
            if (column) {
                formData.value.column = column
                formData.value.order = formData.value.order == 'asc' ? 'desc' : 'asc'
            }
            loadReferenceList(formData.value)
        }
        onMounted(() => {
            modals.modalReference = new Modal('#modalReference', {})
        })
        callLoadReferences()



        return {
            referenceList,
            isLoading,
            formData,
            formData,
            headers,
            errorsFields,
            relationshipSelect,
            isLoadinOperationReference,
            operation,
            clearFormData,
            callLoadReferences,
            codReferencia,
            showAlert,
            messageServer,
            typeAlert,
            tipoRespuesta,
            observacionesRespuesta,
            modalTittle,

            checkPermission,

            onSubmit: async () => {
                if (operation.value != 'edit') {
                    const { response } = await createReference(formData.value)
                    if (response) {
                        clearFormData()
                    }

                    return
                }
                const { response } = await updateReference(formData.value, codReferencia.value)
                if (response) {
                    clearFormData()
                    operation.value = 'newReference'
                    codReferencia.value = ''
                }
            },
            onEdit: (reference) => {
                operation.value = 'edit'
                codReferencia.value = reference.CA115CODREFERENCIA
                formData.value.tipoReferencia = reference.CA115CODTIPOREFERENCIA
                formData.value.nombreReferencia = reference.CA115NOMBRE
                formData.value.apellidoReferencia = reference.CA115APELLIDO
                formData.value.celularReferencia = reference.CA115CELULAR
                formData.value.fijoReferencia = reference.CA115TELEFONOFIJO
                formData.value.parentesco = reference.CA115CODPARENTESCO
            },
            onCancelEdit: () => {
                operation.value = 'newReference'
                codReferencia.value = ''
                clearFormData()
            },
            onDelete: async (reference) => {
                const { isConfirmed } = await Swal.fire({
                    title: '¿Esta seguro?',
                    text: 'Desea eliminar la referencia',
                    showDenyButton: true,
                    confirmButtonText: 'Sí'
                })
                if (isConfirmed) {
                    new Swal({
                        title: 'Espere por favor',
                        allowOutsideClick: false
                    })
                    Swal.showLoading()
                    const { response } = await deleteReference(reference.CA115CODREFERENCIA)
                    if (response) {
                        clearFormData()
                        Swal.fire('Eliminada', '', 'success')
                        return
                    }
                    Swal.fire('Ha ocurrido un error', '', 'error')
                }

            },
            onStoreAnswerReference: async () => {
                const formDataAnswer = {
                    tipoRespuesta: tipoRespuesta.value,
                    observacionesRespuesta: observacionesRespuesta.value
                }
                const { response } = await storeAnswerReference(formDataAnswer, codReferencia.value)
                if (response) {
                    Swal.fire('Información actualizada', '', 'success')
                    modals.modalReference.hide()
                    codReferencia.value = ''
                    modalTittle.value = ''
                }
            },
            // Abrir Modal para referenciar
            openModal: async (reference) => {
                modals.modalReference.show()
                modalTittle.value = modalTittle.value + ' ' + reference.CA115NOMBRE + ' ' + reference.CA115APELLIDO
                codReferencia.value = reference.CA115CODREFERENCIA
                tipoRespuesta.value = reference.CA115TIPORESPUESTA
                observacionesRespuesta.value = reference.CA115OBSERVACIONES
            },

            closeModal: () => {
                modals.modalReference.hide()
            },
        }
    }


}
</script>

<style></style>
