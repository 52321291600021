import {
    isAuthenticatedGuard,
    hasActiveShopAllied,
    hasNoActiveShopAllied,
    isAuthorized,
    hasChangedPassword
} from '@/guard/auth-guard'
import UsersLists from '@/modules/users/views/UsersLists'
import UserSelectShop from '../views/UserSelectShop.vue'
import UserChangePassword from '../views/UserChangePassword.vue'
import UserProfile from '../../profile/views/UserProfile.vue'
export default {
    name: 'users',
    component: () =>
        import( /* webpackChunkName: "DashboardLayout" */ '@/modules/dashboard/layouts/DashboardLayout'),
    children: [
        {
            path: '',
            beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized, hasActiveShopAllied],
            name: 'users-index',
            meta: { permission: 3, showNav: false },
            component: UsersLists
        },
        {
            path: '/select-user-shop',
            beforeEnter: [isAuthenticatedGuard, hasChangedPassword, isAuthorized, hasNoActiveShopAllied],
            name: 'select-user-shop',
            meta: { permission: 12, showNav: false },
            component: UserSelectShop
        },
        {
            path: '/change-password',
            beforeEnter: [isAuthenticatedGuard],
            name: 'change-password',
            meta: {
                title: 'Cambiar contraseña',
                icon: 'fas fa-key',
                showNav: true,
                permission: 0,
                group: 'acount',
            },
            component: UserChangePassword
        },
    ]



}