<template>
    <TheContentHeader titlePage="Notificaciones" />
    <the-content-body>
        <widget-card title="Notificaciones" classHeader="bg-light text-primary">
            <template v-slot:header>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="text-primary h5">
                        <i class="fas fa-bell"></i> Notificaciones
                    </div>
                    <button class="btn btn-outline-primary btn-sm" @click="markAllAsRead()">
                        Marcar todas como leídas
                    </button>
                </div>
            </template>
            <template v-slot:body>
                <widget-alerts v-if="alert.showAlert" :messages="alert.messageServer"
                    :typeAlert="alert.typeAlert"></widget-alerts>
                <widget-loading v-if="isLoading.list" />
                <template v-else-if="notificationList.length > 0">
                    <div class="row">
                        <div v-for="(notification, index) in notificationList" :key="notification.id"
                            class="card mb-3 shadow-sm border-light">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <h5 class="card-title">
                                        {{ titleNotification(notification) }}
                                    </h5>
                                    <widget-spinner v-if="isMarking[index]" :typeSpinner="4"></widget-spinner>
                                    <button v-else @click="onMarkAsRead(notification.id, index)" type="button"
                                        class="btn-close"></button>
                                </div>
                                <p class="card-text">{{ notification.data['descripcion'] }}</p>
                                <small class="text-muted">{{ getDiffForHumans(notification.created_at) }}</small>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-else>
                    <div class="row justify-content-center">
                        <div class="col-12 alert alert-info text-center mt-5">
                            No hay información para mostrar
                        </div>
                    </div>
                </div>
            </template>
        </widget-card>
    </the-content-body>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';


import BaseButtonForms from '@/common/forms/BaseButtonForms.vue';
import BaseInputForms from '@/common/forms/BaseInputForms.vue';
import BaseSelectForms from '@/common/forms/BaseSelectForms.vue';
import BaseSelectList from '@/common/forms/BaseSelectList.vue';
import BaseTableForms from '@/common/forms/BaseTableForms.vue';
import TheContentBody from '@/modules/dashboard/components/TheContentBody.vue';
import TheContentHeader from '@/modules/dashboard/components/TheContentHeader.vue';
import WidgetAlerts from '@/common/widgets/WidgetAlerts.vue';
import WidgetCard from '@/common/widgets/WidgetCard.vue';
import WidgetDropdown from '@/common/widgets/WidgetDropdown.vue';
import WidgetLoading from '@/common/widgets/WidgetLoading.vue';
import WidgetModalBoostrap from '@/common/widgets/WidgetModalBoostrap.vue';
import WidgetSelectPaginator from '@/common/widgets/WidgetSelectPaginator.vue';
import WidgetSpinner from '@/common/widgets/WidgetSpinner.vue'

import useNotification from '@/modules/notification/composables/useNotification';

export default {
    components: {
        WidgetAlerts,
        BaseButtonForms,
        WidgetCard,
        TheContentBody,
        TheContentHeader,
        WidgetDropdown,
        BaseInputForms,
        WidgetLoading,
        WidgetModalBoostrap,
        BaseSelectForms,
        BaseSelectList,
        WidgetSelectPaginator,
        BaseTableForms,
        WidgetSpinner

    },
    setup() {
        const { isLoading, alert, notificationList, markAsRead, markAllAsRead, titleNotification, getDiffForHumans, loadNotificationList } = useNotification();
        const isMarking = ref({});

        loadNotificationList();

        return {
            notificationList,
            alert,
            isLoading,
            getDiffForHumans,
            titleNotification,
            isMarking,
            markAllAsRead,
            onMarkAsRead: async (notificationId, index) => {
                isMarking.value[index] = true;
                await markAsRead(notificationId);
                isMarking.value[index] = false;
            },
        };
    }
};
</script>

<style scoped>
.card {
    transition: all 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-close {
    color: #6c757d;
}

.text-primary {
    font-weight: 600;
}
</style>
