import requestApi from "@/api/requestApi"
import { setErrorsFields } from '@/helpers/errorsFields'

const options = {
    requestGraphics: { url: 'graficoSolicitudes', commit: 'setRequestGraphics' },
    salesGraphics: { url: 'graficoVentas', commit: 'setSalesGraphics' },
    paymentsGraphics: { url: 'graficoRecaudo', commit: 'setPaymentsGraphics' },
    creditGraphics: { url: 'graficoCartera', commit: 'setCreditGraphics' },
    shivamGraphics: { url: 'cuentaShivamAliado', commit: 'setShivamAccountGraphics' },
    precierre: { url: 'precierre', commit: 'setDataReports' },
}

export const getDataReports = async ({ commit }, { formData, typeGraphics }) => {
    try {
        commit('showProcessHideAlert', typeGraphics)
        const { data } = await requestApi.get(`/reportes/${options[typeGraphics].url}`, {
            params: formData
        })

        commit(options[typeGraphics].commit, { data:data.data })
        
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: typeGraphics })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'reports', error: error, typeProcess: typeGraphics })
    }
}
export const getListConfig= async ({ commit }) => {
    try {
        commit('showProcessHideAlert', 'reportsParameters')
        const { data } = await requestApi.get(`/reportes/listConfig`)
        commit('setReportsParameters', { data:data.data })
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true', typeProcess: 'reportsParameters' })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'reports', error: error, typeProcess: 'reportsParameters' })
    }
}
export const exportToExcel = async ({ commit },{formData,typeGraphics}) => {
    try {
        commit('showProcessHideAlert', `${typeGraphics}Export`)
        const { data } = await requestApi.get(`/reportes/${options[typeGraphics].url}/exportar`, {
            params:formData,
            responseType: 'blob'
        })
        let a = document.createElement('a');
        let url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = typeGraphics + new Date().getTime() + '.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        commit('hideProcessShowAlert', { messageServer: '', errorsFields: {}, statusResponse: 'true',typeProcess:`${typeGraphics}Export` })
        return { response: true }
    } catch (error) {
        return setErrorsFields({ modulo: 'reports', error: error, typeProcess: `${typeGraphics}Export` })
    }
}


